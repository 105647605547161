// Core
import { combineReducers } from '@reduxjs/toolkit';
// Instruments
import dashboard from '../core/dashboard/slice';
import tables from '../core/tables/slice';

const rootReducer = combineReducers({
  dashboard,
  tables
});

export { rootReducer };
