// Core
import { SagaIterator } from '@redux-saga/core';
import {
  put, select
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Core
import { tableSettingsColumnHidingEnabledAsyncTypes } from "../asyncAction";
import { setTableGridSettingsColumnHidingEnabled, setTableGridLoading } from '../../slice';
import { selectorsTables } from "../../selectors";
import { localData } from "../../../../../_helpers/localData";

export function* callTableSettingsColumnHidingEnabledWorker(action: PayloadAction<tableSettingsColumnHidingEnabledAsyncTypes>): SagaIterator {
  yield put(setTableGridLoading(true));
  const { payload: { status, gridName } } = action;
  try {
    yield put(setTableGridSettingsColumnHidingEnabled({
      gridName,
      status
    }));
    const prevTableGrid = yield select(selectorsTables.grid.settings);
    const newDate = JSON.parse(JSON.stringify(prevTableGrid));
    newDate[gridName].columnHidingEnabled = status;
    localData.setItem(localData.keysObj.gridsSettings, newDate);
  } catch (error) {
    console.warn(error);
  }
  yield put(setTableGridLoading(false));
}
