// Core
import axios from 'axios';
import { toast } from '../../_helpers/toasts'
import { defaultLng } from "./i18n";

type tablesTypes = {
  filters?: [],
  sort?: [],
  limit: number,
  offset: number
}
type gridSettingsTypes = {
  language?: string,
  grids: string[],
}
type axiosParamsTypes = { url: string, data?: string }
export type employeeTableTypes = {
  user_type_id?: string,
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
});
instance.interceptors.request.use((req) => {
  const language = document.documentElement.lang;
  const currentURLLanguage = language === defaultLng ? '' : language;
  const checkSlashInCurrentUrl = req?.url?.startsWith('/') ? '' : '/';
  const currentLanguageInURL = req?.url?.startsWith(`/${language}/`) && language !== defaultLng;
  const url = currentLanguageInURL ? req.url : `${currentURLLanguage}${checkSlashInCurrentUrl}${req.url}`;

  return {
    ...req,
    url,
  }
})
instance.interceptors.response.use( (response) => {
  if (response.data.status === 'error') {
    toast.show(response.data.text, response.data.title, 'error', false);
  }
  if (response.data.status === 'success') {
    toast.show(response.data.text, response.data.title, 'success', true);
  }
  return response;
},  (error) => {
  if(error.response.data.code === 500) {
    toast.show(error.response.data.errorMessage, error.response.data.error, 'error', false);
  }
  if(error.response.status === 500) {
    toast.show(error.response.data.detail, error.response.data.title, 'error', false);
  }
  return Promise.reject(error);
  }
);

export const axiosAPI = Object.freeze({
  _helpers: {
    getAxios({ url }: axiosParamsTypes) {
      return instance.get(url);
    },
    postAxios(params: axiosParamsTypes) {
      const { url, data } = params;
      return instance.post(url, {
        data
      });
    },
    gridSettings(params: gridSettingsTypes) {
      return instance.post('grid-settings', params);
    },
  },
  reports: {
    absence(params: tablesTypes) {
      return instance.post('reports/absence', params);
    },
    loggedTime(params: tablesTypes) {
      return instance.post('reports/logged-time', params);
    },
    performance(params: tablesTypes) {
      return instance.post('reports/performance', params);
    },
    overtimes: {
      table(params: tablesTypes) {
        return instance.post('reports/overtimes', params);
      },
    },
    employeesRatio(params: tablesTypes) {
      return instance.post('reports/employees-ratio-per-month', params);
    },
    employeesRatioUnit(params: tablesTypes) {
      return instance.post('reports/employees-ratio-per-month-unit', params);
    },
    hoursUtilization(params: tablesTypes) {
      return instance.post('reports/hours-utilization', params);
    },
    attritionRate(params: tablesTypes) {
      return instance.post('reports/attrition-rate', params);
    },
  },
  charts: {
    data(params: any) {
      return instance.post('charts', params);
    },
  },
  dashboard: {
    calendar: {
      data() {
        return instance.post('calendar');
      },
      settings() {
        return instance.post('calendar/settings');
      }
    },
  },
  projectsList: {
    projects(params: tablesTypes) {
      return instance.post('projects', params);
    },
    table(params: tablesTypes) {
      return instance.post('user_projects', params);
    },
  },
  projectTimes: {
    projectsElapsed(params: tablesTypes) {
      return instance.post('projects-statistics', params);
    },
    projectsTrackedTime(params: tablesTypes) {
      return instance.post('projects-data', params);
    },
  },
  registeredUsers: {
    table(params: tablesTypes) {
      return instance.post('newcomers', params);
    },
  },
  userCompensation: {
    table(params: tablesTypes) {
      return instance.post('compensations', params);
    },
  },
  employee: {
    table(params: employeeTableTypes & tablesTypes) {
      return instance.post('employee', params);
    },
  },
  bench: {
    table(params: tablesTypes) {
      return instance.post('bench/', params);
    },
  },
  projectTimeMonth: {
    table(params: tablesTypes) {
      return instance.post('project/month-timesheet', params);
    },
    edit(params: tablesTypes) {
      return instance.post(`project/month-timesheet/edit`, params);
    },
    log(params: tablesTypes) {
      return instance.post(`project/month-timesheet/log`, params);
    }
  },
  teams: {
    table(params: tablesTypes) {
      return instance.post('teams', params);
    },
  },
  holidays: {
    table(params: tablesTypes) {
      return instance.post('holidays', params);
    },
  },
  vacation: {
    controller: {
      table(params: tablesTypes) {
        return instance.post('absences', params);
      },
    },
    balance: {
      table(params: tablesTypes) {
        return instance.post('absences/balance', params);
      },
    },
    balanceHistory: {
      table(params: tablesTypes) {
        return instance.post('absences/history-balance', params);
      },
    },
  },
  overtimes: {
    table(params: tablesTypes) {
      return instance.post('overtimes', params);
    },
  },
  subscriptionsList: {
    table(params: tablesTypes) {
      return instance.post('subscriptions', params);
    },
  },
  settings: {
    widgets: {
      table(params: tablesTypes) {
        return instance.post('settings/dashboards', params);
      },
    },
    positions: {
      table(params: tablesTypes) {
        return instance.post('settings/positions', params);
      },
    },
    vacationTypes: {
      table(params: tablesTypes) {
        return instance.post('settings/absence-types', params);
      },
    },
    employeeTypes: {
      table(params: tablesTypes) {
        return instance.post('settings/user-types', params);
      },
    },
    trackers: {
      table(params: tablesTypes) {
        return instance.post('settings/trackers', params);
      },
    },
    events: {
      table(params: tablesTypes) {
        return instance.post('events', params);
      },
    },
    mailTemplates: {
      table(params: tablesTypes) {
        return instance.post('settings/mail-templates', params);
      },
    },
    ssoGroups: {
      table(params: tablesTypes) {
        return instance.post('settings/sso-groups', params);
      },
    },
    ssoGroupEmployee: {
      table(params: tablesTypes) {
        return instance.post('settings/sso-groups/employees', params);
      },
    },
    mailChains: {
      table(params: tablesTypes) {
        return instance.post('/settings/mail-chains', params);
      },
    },
    mailChainCategories: {
      table(params: tablesTypes) {
        return instance.post('settings/mail-chain-categories ', params);
      },
    },
    leaveReasons(params: tablesTypes) {
      return instance.post('settings/user-leave-reason', params);
    },
    currencies(params: tablesTypes) {
      return instance.post('settings/currencies', params);
    },
    companyLinks(params: tablesTypes) {
      return instance.post('settings/company-links', params);
    },
  },
});
