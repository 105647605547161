// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from "react-i18next";
// Parts
import Table, { TableApiParamsType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const ProjectTrackedTime:FC<{ toolbar: ToolbarType }> = (props) => {
  const { toolbar } = props;
  const params = new URLSearchParams(window.location.search)

  const projectId = document.getElementById('projectId');
  const hashCachePos = document.getElementById('react_container__portal--project_positions')?.dataset.hashCache;
  const hashCacheEmpl = document.getElementById('react_container__portal--project_employees')?.dataset.hashCache;

  const gridParams = {
    grids: [{ name: 'projectTrackedTimeByPosition', hashCache: hashCachePos }, { name: 'projectTrackedTimeByEmployee', hashCache: hashCacheEmpl }],
    params: { "project_show" : projectId?.dataset.project_id }
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      ot: params.get('ot'),
      date_from: params.get('date_from'),
      date_to: params.get('date_to'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      project: projectId?.dataset.project_id,
      type: 'projectTrackedTimeByPosition',
      ...pageParams
    });

  return <Table
    toolbar={toolbar}
    api={axiosAPI.projectTimes.projectsTrackedTime}
    gridParams={gridParams}
    gridName="projectTrackedTimeByPosition"
    apiParams={apiParams}
  />;
}

function ProjectTrackedTimePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Positions')
  }];
  const Root = document.getElementById(id);
  return Root ? createPortal(<ProjectTrackedTime  toolbar={toolbar} />, Root) : null;
}

export default ProjectTrackedTimePortal;
