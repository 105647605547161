// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const params = new URLSearchParams(window.location.search);

const ProjectsElapsed:FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;

  const gridParams = {
    grids: [{ name: 'projectStatistics', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      dateFrom: params.get('dateFrom'),
      dateTo: params.get('dateTo'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
  });

  return <Table
    toolbar={toolbar}
    gridParams={gridParams}
    api={axiosAPI.projectTimes.projectsElapsed}
    apiParams={apiParams}
    sortingMode="multiple"
    gridName="projectStatistics"
  />;
}

function ProjectsElapsedPortal({ id }: { id: string }) {
  const { t } = useTranslation();
  const Root = document.getElementById(id);
  const toolbar = [{
    names: 'date',
    type: 'rangedatepicker',
    placeholder: t('Show'),
    defaultValue: {
      dateFrom: new Date(params.get('dateFrom') || ''),
      dateTo: new Date(params.get('dateTo') || '')
    },
  }];

  return Root ? createPortal(<ProjectsElapsed hashCache={Root?.dataset.hashCache} toolbar={toolbar} />, Root) : null;
}

export default ProjectsElapsedPortal;
