// Core
import React, { FC, ChangeEvent } from 'react';
// MUI
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const AntSwitchStyled = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: "#2f2f2f",
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

type AntSwitchType = {
  defaultChecked?: boolean
  checked?: boolean,
  handleChange: (status: boolean) => void;
}

const AntSwitch: FC<AntSwitchType> = (props) => {
  const { defaultChecked, handleChange, checked } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked)
  };
  return <AntSwitchStyled defaultChecked={defaultChecked} onChange={onChange} checked={checked} />
}

export default AntSwitch;
