// Core
import React, { FC } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';

// UI
import ButtonUI from '@mui/material/Button';
import Loader from '../../icon/Loader';

// Style
const useStyles = makeStyles(() =>
  createStyles({
    buttonLink: {
      textDecoration: 'none',
      // display: 'inline-flex'
    },
    pointer: {
      pointerEvents: "none",
    },
    root: {
      position: 'relative',
      pointerEvents: 'none',
    },
    loader: {
      position: 'absolute',
      left: 0,
      right: 0,
      textAlign: 'center',
      animation: `$myEffect 2000ms linear infinite`,
    },
    opacity: {
      opacity: 0.5,
    },
    '@keyframes myEffect': {
      to: { transform: 'rotate(360deg)' },
    },
  }),
);

interface ButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  reactHref?: string;
  startIcon?: React.ReactNode;
  loader?: boolean;
  variant?: 'contained' | 'outlined';
  color?: 'inherit' | 'primary' | 'secondary';
  type?: 'button' | 'submit';
  className?: string;
  onClick?: any; /* TODO  UserMenu иначе не работает. */
  classes?: any; /* TODO должен быть объект, но на объект ругается */
}

const Button: FC<ButtonProps> = (
  {
    reactHref,
    type,
    disabled,
    fullWidth,
    className,
    href,
    startIcon,
    children,
    loader,
    variant,
    color,
    onClick,
    classes,
  }) => {
  const classNames = useStyles();

  const ButtonComponent = (
    <ButtonUI
      className={clsx(className, {
        [classNames.root]: loader,
        [classNames.pointer]: href === undefined && reactHref === undefined && onClick === undefined && type !== 'submit'
      })}
      type={type}
      classes={classes}
      variant={variant}
      color={color}
      href={reactHref ? undefined : href}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
      onClick={onClick}
    >
      {loader ? (
        <>
          <span className={classNames.loader}>
            <Loader/>
          </span>{' '}
          <span className={classNames.opacity}>{children}</span>
        </>
      ) : (
        children
      )}
    </ButtonUI>
  )

  return ButtonComponent;
};

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  loader: false,
  color: 'primary',
  type: 'button',
  startIcon: false,
};

export default Button;
