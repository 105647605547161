// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const CurrenciesTable: FC = () => {

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'currencies' }],
  }

  return <Table
    api={axiosAPI.settings.currencies}
    gridParams={gridParams}
    gridName="currencies"
    apiParams={apiParams}
  />;
}

function CurrenciesTablePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<CurrenciesTable/>, Root) : null;
}

export default CurrenciesTablePortal;
