import React, { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Photo: FC = () => (
  <SvgIcon viewBox="0 0 16 14">
    <path
      d="M3.24752 2.63293L3.65187 1.38798C3.77578 0.997275 4.12796 0.739014 4.51926 0.739014H11.4845C11.8758 0.739014 12.228 0.997275 12.3519 1.38798L12.7562 2.63293H13.9888C15.0062 2.63293 15.828 3.48055 15.828 4.52022V12.0892C15.828 13.1355 15.0062 13.9832 13.9888 13.9832H2.01491C0.99752 13.9832 0.175781 13.1355 0.175781 12.0892V4.52022C0.175781 3.48055 0.99752 2.63293 2.01491 2.63293H3.24752ZM8.00187 11.8575C10.1606 11.8575 11.9149 10.0563 11.9149 7.83125C11.9149 5.61286 10.1606 3.81166 8.00187 3.81166C5.84317 3.81166 4.08882 5.61286 4.08882 7.83125C4.08882 10.0563 5.84317 11.8575 8.00187 11.8575ZM8.00187 10.4337C6.60622 10.4337 5.47143 9.26824 5.47143 7.83125C5.47143 6.39427 6.60622 5.2354 8.00187 5.2354C9.39752 5.2354 10.5323 6.39427 10.5323 7.83125C10.5323 9.26824 9.39752 10.4337 8.00187 10.4337Z" />
  </SvgIcon>
);

export default Photo;
