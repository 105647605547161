// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const LoggedTimeTable: FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    type: params.get('type'),
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...pageParams
  });
  const loggedTimeParams = {
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
  }

  const gridParams = {
    grids: [{ name: `${params.get('type')}`, hashCache }],
    params: loggedTimeParams
  }
  const currentGridName = params.get('type')

  return <Table
    stateStoringEnabled={false}
    toolbar={toolbar}
    api={axiosAPI.reports.loggedTime}
    gridParams={gridParams}
    gridName={`${currentGridName}`}
    apiParams={apiParams}
  />;
}

function LoggedTimeTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Logged time')
  }];
  const Root = document.getElementById(id);
  return Root ? createPortal(<LoggedTimeTable hashCache={Root?.dataset.hashCache}  toolbar={toolbar}/>, Root) : null;
}

export default LoggedTimeTablePortal;
