// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const PerformanceTable: FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const performanceParams = {
    date: params.get('date')
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...performanceParams,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'performance', hashCache }],
    params: performanceParams
  }

  return <Table
    stateStoringEnabled={false}
    toolbar={toolbar}
    api={axiosAPI.reports.performance}
    gridParams={gridParams}
    gridName="performance"
    apiParams={apiParams}
  />;
}

function PerformanceTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Performance')
  }];

  const Root = document.getElementById(id);

  return Root ? createPortal(
    <PerformanceTable
      hashCache={Root.dataset.hashCache}
      toolbar={toolbar}
    />, Root) : null;
}

export default PerformanceTablePortal;
