export const tableColumnsProjects = {
  "ru": {
    title: {
      dataField: 'title',
      caption: 'Название',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    project_url: {
      dataField: 'project_url',
      caption: 'URL',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    createdAt: {
      dataField: 'createdAt',
      caption: 'Создано',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    },
    updatedAt: {
      dataField: 'updatedAt',
      caption: 'Добавлено',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    }
  },
  "en": {
    title: {
      dataField: 'title',
      caption: 'Name',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    project_url: {
      dataField: 'project_url',
      caption: 'URL',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    createdAt: {
      dataField: 'createdAt',
      caption: 'Created',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    },
    updatedAt: {
      dataField: 'updatedAt',
      caption: 'Updated',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    }
  },
  "uk": {
    title: {
      dataField: 'title',
      caption: 'Назва',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    project_url: {
      dataField: 'project_url',
      caption: 'URL',
      dataType: "string",
      allowSorting: true,
      filterOperations: ['contains'],
    },
    createdAt: {
      dataField: 'createdAt',
      caption: 'Створено',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    },
    updatedAt: {
      dataField: 'updatedAt',
      caption: 'Додано',
      dataType: "date",
      allowSorting: true,
      filterOperations: ['=', 'between'],
    }
  }
}

export const tableColumnsProjectsElapsed = {
  "ru": {
    projectTitle: {
      dataField: 'projectTitle',
      caption: 'Название проекта',
      dataType: "string",
      position: 1,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    user: {
      dataField: 'user',
      caption: 'Имя сотрудника',
      dataType: 'string',
      position: 2,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    time: {
      dataField: 'time',
      caption: 'Затраченое время (часы)',
      dataType: 'string',
      position: 3,
      allowSorting: true,
      filterOperations: ['='],
    },
    timeRmClient: {
      dataField: 'timeRmClient',
      caption: 'Время',
      dataType: 'string',
      position: 4,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeRmClient: {
      dataField: 'downtimeRmClient',
      caption: 'Отклоненный простой',
      dataType: 'string',
      position: 5,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeConfirmedRmClient: {
      dataField: 'downtimeConfirmedRmClient',
      caption: 'Подтвержденный простой',
      dataType: 'string',
      position: 6,
      allowSorting: true,
      filterOperations: ['='],
    }
  },
  "en": {
    projectTitle: {
      dataField: 'projectTitle',
      caption: 'Name project',
      dataType: "string",
      position: 1,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    user: {
      dataField: 'user',
      caption: 'User',
      dataType: 'string',
      position: 2,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    time: {
      dataField: 'time',
      caption: 'Tracked time',
      dataType: 'string',
      position: 3,
      allowSorting: true,
      filterOperations: ['='],
    },
    timeRmClient: {
      dataField: 'timeRmClient',
      caption: 'Time',
      dataType: 'string',
      position: 4,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeRmClient: {
      dataField: 'downtimeRmClient',
      caption: 'Rejected downtime',
      dataType: 'string',
      position: 5,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeConfirmedRmClient: {
      dataField: 'downtimeConfirmedRmClient',
      caption: 'Accepted downtime',
      dataType: 'string',
      position: 6,
      allowSorting: true,
      filterOperations: ['='],
    }
  },
  "uk": {
    projectTitle: {
      dataField: 'projectTitle',
      caption: 'Назва проекту',
      dataType: "string",
      position: 1,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    user: {
      dataField: 'user',
      caption: 'Ім\'я співробітника',
      dataType: 'string',
      position: 2,
      allowSorting: true,
      filterOperations: ['contains'],
    },
    time: {
      dataField: 'time',
      caption: 'Витрачений час (години)',
      dataType: 'string',
      position: 3,
      allowSorting: true,
      filterOperations: ['='],
    },
    timeRmClient: {
      dataField: 'timeRmClient',
      caption: 'Час',
      dataType: 'string',
      position: 4,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeRmClient: {
      dataField: 'downtimeRmClient',
      caption: 'Відхилений простій',
      dataType: 'string',
      position: 5,
      allowSorting: true,
      filterOperations: ['='],
    },
    downtimeConfirmedRmClient: {
      dataField: 'downtimeConfirmedRmClient',
      caption: 'Підтверджений простій',
      dataType: 'string',
      position: 6,
      allowSorting: true,
      filterOperations: ['='],
    }
  },
}
