// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../components/Table";
// Engine
import { axiosAPI } from "../../engine/config/axios";

const TeamsTable:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });
  const gridParams = {
    grids: [{ name: 'teams', hashCache }],
  }

  return <Table
    api={axiosAPI.teams.table}
    apiParams={apiParams}
    sortingMode="multiple"
    gridName="teams"
    gridParams={gridParams}
  />;
}

function TeamsTablePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<TeamsTable hashCache={Root?.dataset.hashCache} />, Root) : null;
}

export default TeamsTablePortal;
