// Core
import React from 'react';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
// DX
import { exportDataGrid } from 'devextreme/excel_exporter';
// MUI
import Tooltip from '@mui/material/Tooltip';
// Parts
import { formattedDate } from "../../ColumnRender/components/CellRender";
import { nameColor } from "../../ColumnRender/components/CellRender/styles";
import { IconButton } from "../../../../IconButton";
// Engine
import i18n from "../../../../../../engine/config/i18n";
import { store } from "../../../../../../engine/init/store";


export const onExporting = (e:any, gridName: string) => {
  const { tables } = store.getState();
  const titleTooltip = tables?.grid?.titleTooltip?.[gridName];
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('file');

  const getLastPathUrl = () => {
    const currentUrl = window.location.pathname;
    const urlParts = currentUrl.split('/');

    return urlParts[urlParts.length - 1];
  }

  const getDateOrPeriodFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const ot = urlParams.get('ot');
    const date = urlParams.get('date');

    if (ot && titleTooltip && titleTooltip.type === 'datePeriod') {
      return `_${titleTooltip?.value?.start}-${titleTooltip?.value?.end}`;
    }

    if (!ot && date) {
      return `_${urlParams.get('date')}`;
    }

    return '';
  }

  const getProject = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const project = urlParams.get('project');

    return project ? `_${project}` : '';
  }

  const getCompanyName = () => {
    const companyName = localStorage.getItem('companyName');

    return companyName && `_${companyName.replace(/\s+/g, '_')}`;
  }


  exportDataGrid({
    component: e.component,
    worksheet,
    customizeCell(options) {
      const { gridCell, excelCell } = options;
      const gridCellValue = gridCell?.value;
      const currentValue = gridCellValue?.name || gridCellValue?.text || gridCellValue?.value || gridCellValue?.title || gridCell?.value;

      if (gridCell?.rowType === 'data') {
        if (gridCell?.column?.dataType === 'boolean') {
          excelCell.value = currentValue;
        } else if (typeof gridCellValue === 'object') {
          if (currentValue && typeof currentValue !== 'object') {
            switch (gridCell?.column?.dataField) {
              case 'user': {
                if (gridCell.data?.hired && !gridCell.data?.fired) {
                  excelCell.fill = { type: 'pattern', pattern:'solid', fgColor:{ argb: nameColor.hired.backgroundColor } };
                  excelCell.font = { color: { argb: nameColor.hired.color } };
                } else if (gridCell.data?.fired && !gridCell.data?.hired) {
                  excelCell.fill = { type: 'pattern', pattern:'solid', fgColor:{ argb: nameColor.fired.backgroundColor } };
                  excelCell.font = { color: { argb: nameColor.fired.color } };
                } else if (gridCell.data?.fired && gridCell.data?.hired) {
                  excelCell.fill = { type: 'pattern', pattern:'solid', fgColor:{ argb: nameColor.hiredFired.backgroundColor } };
                  excelCell.font = { color: { argb: nameColor.hiredFired.color } };
                }
                excelCell.value = currentValue;
                break;
              }
              default: {
                excelCell.value = currentValue;
              }
            }
          } else {
            switch (gridCell?.column?.dataType) {
              case 'date': {
                excelCell.value = formattedDate(gridCellValue);
                break;
              }
              default: {
                switch (gridCell?.column?.dataField) {
                  case 'size': {
                    excelCell.value = gridCellValue.value;
                    break;
                  }
                  case 'event': {
                    excelCell.value = gridCellValue.value;
                    break;
                  }
                  case 'type': {
                    excelCell.value = gridCellValue.value;
                    break;
                  }
                  case 'project': {
                    excelCell.value = '';
                    break;
                  }
                  case 'url':
                    excelCell.value = Object.keys(gridCellValue).map((key, index) => gridCellValue[key].text).join(', ');
                    break;
                }
              }
            }
          }
        } else if (typeof gridCellValue === 'string') {
          excelCell.value = gridCellValue;
        }
      }
    }
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${getLastPathUrl()}${getCompanyName()}${getDateOrPeriodFromUrl()}${getProject()}.xlsx`);
    });
  });
  // eslint-disable-next-line no-param-reassign
  e.cancel = true;
}

const ExportingComponent = (props: any) => {
  const { data } = props;
  return <Tooltip title={i18n.t('toolbar.export').toString()} enterTouchDelay={0}>
    <IconButton
      aria-label={i18n.t('toolbar.export')}
      size="small"
      onClick={data.options.onClick}
    >
      <i className="dx-icon dx-icon-xlsxfile" />
    </IconButton>
  </Tooltip>
};

export default ExportingComponent;
