// Core
import React, { FC, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, {
  TableApiParamsType, ToolbarType, TableHashCacheType,
  TableCanColumnHidingType
} from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const params = new URLSearchParams(window.location.search)
const EmployeesRatioPerMonthTable: FC<TableHashCacheType & TableCanColumnHidingType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache, canChangeColumnHiding } = props;
  const isTeamFilterType = params.get('filter_type') === 'team';

  const apiParams: TableApiParamsType = useCallback(
    (loadOptions, pageParams) => ({
      ot: params.get('ot'),
      date_from: params.get('date_from'),
      date_to: params.get('date_to'),
      filters: isTeamFilterType ? {
        team: params.getAll('team[]')
      } : {
        unit: params.getAll('unit')
      },
      sort: loadOptions?.sort,
      ratio_per_month: params.get('ratio_per_month') === 'on',
      enabled_project: params.get('hide_projects') !== 'on',
      ...pageParams
    }),
    [isTeamFilterType]
  );

  const gridParams = useMemo(() => ({
      grids: [{
        name: isTeamFilterType ? 'employeesPerMonthRatio' : 'employeesPerMonthRatioUnit',
        hashCache,
        canChangeColumnHiding
      }],
    }), [isTeamFilterType, hashCache, canChangeColumnHiding])

  return <Table
    toolbar={toolbar}
    api={isTeamFilterType ? axiosAPI.reports.employeesRatio : axiosAPI.reports.employeesRatioUnit}
    gridParams={gridParams}
    gridName={isTeamFilterType ? 'employeesPerMonthRatio' : 'employeesPerMonthRatioUnit'}
    apiParams={apiParams}
    type='virtual'
    groupByMonth={params.get('ratio_per_month') === 'on'}
  />;
}

function EmployeesRatioPerMonthTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Head count')
  }];
  const Root = document.getElementById(id);
  return Root
    ? createPortal(
      <EmployeesRatioPerMonthTable
        hashCache={Root?.dataset.hashCache}
        canChangeColumnHiding={false}
        toolbar={toolbar}
      />, Root
    )
    : null;
}

export default EmployeesRatioPerMonthTablePortal;
