// Core
import React, { FC, useRef, useState } from 'react';
import clsx from "clsx";
import Cropper from "react-cropper";
// UI
import Slider from "@mui/material/Slider";
import Button from "./components/Button";
import Photo from "./icon/Photo";
// Hooks
// Style
import "cropperjs/dist/cropper.css";
import { useStyles } from './styles';

type CropperProps = {
  currentImg?: string;
  loader?: boolean;
  onSend?: (dataObject: { data: any, image: string }) => void;
}

const PhotoCropper: FC<CropperProps> = ({ currentImg, onSend, loader }) => {
  const [image, setImage] = useState(currentImg);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [editMode, setEditMode] = useState(false);
  const classes = useStyles();
  const editable = document.body.classList.contains('page--profile_edit');

  const onChangeSlider = (e: any, value: any) => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const currentValue = Number(parseFloat(value).toFixed(4));
    cropper.scale(currentValue);
  }
  const getCropData = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (typeof cropper !== "undefined") {
      if (onSend) {
        onSend({
          data: cropper.getImageData(),
          image: imageElement?.cropper.getCroppedCanvas().toDataURL()
        })
      }
      setEditMode(false);
    }
  };

  const onChangeFile = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImage(reader.result.toString());
      }
    };
    reader.readAsDataURL(files[0]);
    setEditMode(true);
  };
  return <>
    <Cropper
      viewMode={3}
      dragMode="move"
      center
      aspectRatio={1}
      cropBoxResizable={false}
      cropBoxMovable={false}
      zoomOnTouch={false}
      zoomOnWheel={false}
      guides={false}
      highlight={false}
      src={image}
      ref={cropperRef}
      onInitialized={this}
      className={clsx(classes.cropper, {
        [classes.disableCrop]: !editMode,
        [classes.offBg]: !editMode && loader !== true,
      })}
      autoCropArea={1}
      minContainerHeight={200}
      minContainerWidth={200}
      minCanvasHeight={200}
      minCanvasWidth={200}
      minCropBoxHeight={200}
      minCropBoxWidth={200}
    />
    {editMode && <Slider
      min={1}
      max={3}
      step={0.0001}
      defaultValue={0}
      className={classes.slider}
      onChange={onChangeSlider}
    />}
    <div className={classes.profileInfoPhotoChange}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!editMode && loader !== true
        ? ( editable ?
          <label htmlFor="fileLoader" className={classes.iconButton}>
            <input id="fileLoader" type="file" onChange={onChangeFile}/>
            <Photo />
          </label> : null
        ) : (
          <Button variant='contained' onClick={getCropData} loader={loader}>
            save
          </Button>
        )}
    </div>
  </>
};

export default PhotoCropper;
