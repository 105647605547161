// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, TableHashCacheType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const Projects:FC<TableHashCacheType& { toolbar: ToolbarType }> = (props) => {
  const { hashCache, toolbar } = props;
  const params = new URLSearchParams(window.location.search);
  const gridParams = {
    grids: [{ name: 'projects', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      project_state_id: params.get('project_state_id'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  return <Table
    toolbar={toolbar}
    api={axiosAPI.projectsList.projects}
    gridParams={gridParams}
    gridName="projects"
    sortingMode="multiple"
    apiParams={apiParams}
  />;
}

function ProjectsPortal({ id }: { id: string }) {
  const { t } = useTranslation();
  const Root = document.getElementById(id);
  const toolbar = [{
    name: 'project_state_id',
    type: 'select',
    placeholder: t('Show'),
    values: Root?.dataset?.toolbar_project_state_id || ''
  }];
  return Root ? createPortal(<Projects hashCache={Root?.dataset.hashCache} toolbar={toolbar}  />, Root) : null;
}

export default ProjectsPortal;
