// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";
// Config

const Overtimes:FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const gridParams = {
    grids: [{ name: 'overtimes', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      overtime_status: params.get('overtime_status'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  return <Table
    toolbar={toolbar}
    api={axiosAPI.overtimes.table}
    apiParams={apiParams}
    gridParams={gridParams}
    gridName="overtimes"
    sortingMode="multiple"
  />;
}

function OvertimesPortal({ id }: { id: string }) {
  const { t } = useTranslation();
  const Root = document.getElementById(id);
  const toolbar = [{
    name: 'overtime_status',
    type: 'select',
    placeholder: t('Show'),
    values: Root?.dataset?.toolbar_overtime_status || ''
  }];
  return Root ? createPortal(<Overtimes toolbar={toolbar} />, Root) : null;
}

export default OvertimesPortal;
