// Engine
import { RootState } from '../../init/store';

export const selectorsDashboard = Object.freeze({
  dashboard: {
    settings: {
      loading: (state: RootState) => state.dashboard.calendar.settings.loading,
      date: (state: RootState) => state.dashboard.calendar.settings.data,
    }
  },
});
