// Core
import { SagaIterator } from '@redux-saga/core';
import {
  select,
  put,
  apply,
  call
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { localData } from '../../../../../_helpers/localData';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { tablesSettingsPostAsyncTypes } from "../asyncAction";
import { selectorsTables } from "../../selectors";
import {
  setTableGridSettings,
  setTableGridLoading
} from '../../slice';

export function* callTableSettingsPostWorker(action: PayloadAction<tablesSettingsPostAsyncTypes>): SagaIterator {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParamsMap = [];

  // @ts-ignore
  for(const item of urlParams.entries()) {
    urlParamsMap.push({
      'key'   : item[0],
      'value' : urlParams.getAll(item[0]),
    });
  }

  const { payload: { forceUpdate, grids, params } } = action;
  yield put(setTableGridLoading(true));
  try {
    const localGrids = yield apply(localData, localData.getItem, [localData.keysObj.gridsSettings]);
    const normalizeParams = {
      ...params,
      grids: grids.map(item => item.name),
      date: grids.map(item => item.date),
      getParameters: urlParamsMap
    }
    for (const item of grids) {
      const prevTableGrid = yield select(selectorsTables.grid.settings);
      if (
        localGrids === null
        || !Boolean(item.hashCache)
        || item.hashCache !== localGrids?.[item.name]?.hashCache
        || item.canChangeColumnHiding !== localGrids?.[item.name]?.canChangeColumnHiding
        || item.canChangeColumnHiding === undefined
        || forceUpdate
      ) {
        const { data } = yield call(axiosAPI._helpers.gridSettings, normalizeParams);
        const currentGridsSettings = grids.reduce((obj, gridsSettings) => ({
            ...obj,
            [gridsSettings.name]: {
              canChangeColumnHiding: gridsSettings.canChangeColumnHiding === undefined
                ? true
                : gridsSettings.canChangeColumnHiding,
              columnHidingEnabled:
                localGrids?.[item.name]?.columnHidingEnabled === undefined
                  ? gridsSettings.canChangeColumnHiding !== false
                  : localGrids?.[item.name]?.columnHidingEnabled,
              hashCache: gridsSettings.hashCache,
              grid: data[gridsSettings.name],
            }
          }), localGrids);

        localData.setItem(localData.keysObj.gridsSettings, currentGridsSettings);
        yield put(setTableGridSettings(currentGridsSettings));
        break
      } else {
        yield put(setTableGridSettings({ ...prevTableGrid, [item.name]: localGrids[item.name] }));
      }
    }
  } catch (error) {
    console.warn(error);
  }
  yield put(setTableGridLoading(false));
}
