import React from 'react';
import { createPortal } from "react-dom";

// Parts
import Scheduler from "../../components/Scheduler";

function DashboardSchedulerPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<Scheduler />, Root) : null;
}

export default DashboardSchedulerPortal;
