// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const LeaveReasonsTable: FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;
  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'userLeaveReasons', hashCache }],
  }

  return <Table
    api={axiosAPI.settings.leaveReasons}
    gridParams={gridParams}
    gridName="userLeaveReasons"
    apiParams={apiParams}
  />;
}

function LeaveReasonsTablePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<LeaveReasonsTable hashCache={Root?.dataset.hashCache} />, Root) : null;
}

export default LeaveReasonsTablePortal;
