// Core
import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  grid: {
    settings: null | {
      [key: string]: {
        grid: {
          [key: string]: {
            allowSorting?: boolean
            visible?: boolean
            caption: string
            dataField: string
            dataType: string
            filterOperations: string[]
            position: number
          }
        }
        canChangeColumnHiding: boolean,
        columnHidingEnabled: boolean,
        hashCache?: string,
      }
    },
    titleTooltip?: {
      [tableName: string]: {
        type?: string
        value?: { start: string, end: string }
      }}
    loading: boolean,
  }
};

const tablesSlice = createSlice({
  name: 'tables',
  initialState: {
    grid: {
      settings: null,
      titleTooltip: undefined,
      loading: true,
    },
  } as InitialStateType,
  reducers: {
    setTableGridSettings(state, action) {
      state.grid.settings = action.payload;
    },
    setTableGridSettingsColumnHidingEnabled(state, action) {
      if (state.grid.settings?.[action.payload.gridName]) {
        state.grid.settings[action.payload.gridName].columnHidingEnabled = action.payload.status;
      }
    },
    setTableGridTitleTooltip(state, action) {
      state.grid.titleTooltip = action.payload;
    },
    setTableGridLoading(state, action) {
      state.grid.loading = action.payload;
    },
  },
});

export const {
  setTableGridSettings,
  setTableGridSettingsColumnHidingEnabled,
  setTableGridLoading,
  setTableGridTitleTooltip
} = tablesSlice.actions;

export default tablesSlice.reducer;
