// Style
import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('md')]: {
      flexDirection: "row",
    },
    "@global": {
      ".dx-scheduler-header-panel-cell .dx-scheduler-header-panel-cell-date:last-child": {
        [theme.breakpoints.down(450)]: {
          fontSize: "initial !important"
        },
      },
      ".dx-scheduler-all-day-title": {
        lineHeight: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      ".dx-scheduler-header": {
        [theme.breakpoints.down(450)]: {
          height: 74,
        },
        "& .dx-toolbar-before": {
          [theme.breakpoints.down(450)]: {
            bottom: "auto !important", top: "0 !important", height: "30px !important",
            padding: "0", left: "0", right: "0", margin: "auto"
          },
        },
        "& .dx-toolbar-after": {
          [theme.breakpoints.down(450)]: {
            bottom: "0 !important", top: "auto !important", height: "30px !important",
            padding: "0 0 0 26px", left: "0", right: "0", margin: "auto",
          },
        }
      }
    }
  },
  map: {
    "@global": {
      "label": {
        marginTop: 0,
        marginBottom: 0
      },
      ".MuiFormControlLabel-label": {
        lineHeight: 1.2,
        fontSize: 14,
      }
    },
  },
  mapTitle: {
    textTransform: "capitalize"
  },
  label: {
    marginTop: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default useStyles;
