// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import { useTranslation } from "react-i18next";
import Table, { TableApiParamsType, TableCanColumnHidingType, TableHashCacheType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const params = new URLSearchParams(window.location.search)

const ProjectTimeMonthLog:FC<TableHashCacheType & TableCanColumnHidingType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache, canChangeColumnHiding } = props;

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      revision: params.get('revision'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  const gridParams = {
    grids: [{ name: 'projectTimeMonthLog', hashCache, canChangeColumnHiding, revision: params.get('revision') ?? null }],
  }

  return <Table
    toolbar={toolbar}
    api={axiosAPI.projectTimeMonth.log}
    apiParams={apiParams}
    gridName="projectTimeMonthLog"
    gridParams={gridParams}
  />;
}

function ProjectTimeMonthLogPortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'name',
    value: t('Month Timesheet Log')
  }];

  const Root = document.getElementById(id);

  return Root ? createPortal(
    <ProjectTimeMonthLog hashCache={Root?.dataset.hashCache} canChangeColumnHiding toolbar={toolbar}/>, Root
  ) : null;
}

export default ProjectTimeMonthLogPortal;
