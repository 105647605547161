// Style
import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    backgroundColor: '#fff',
    padding: '40px',
  },
  action: {
    color: '#777!important'
  },
  activeAction: {
    color: '#1f88e5!important',
  },
  disabledAction: {
    pointerEvents: 'none'
  },
  modalTitle: {
    marginBottom: '20px'
  },
  label: {
    marginTop: 0,
  },
}));

export default useStyles;
