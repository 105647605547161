// Core
import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { loadMessages, locale } from "devextreme/localization";
import find from 'lodash/find';
import filter from 'lodash/filter';
import { AutoSizer } from 'react-virtualized';
// DX
// eslint-disable-next-line import/no-unresolved
import { LoadOptions } from 'devextreme/data';
import DataGrid, {
  ColumnChooser, Editing, Export,
  FilterRow, Pager,
  Paging, Sorting, StateStoring,
  Scrolling, LoadPanel, HeaderFilter
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import ruMessages from 'devextreme/localization/messages/ru.json';
import ukMessages from '../../../engine/config/devextreme/localization/messages/uk.json';
// Parts
import { onExporting } from "./components/ToolbarRender/components/Exporting";
import { ColumnRender } from "./components/ColumnRender";
import "./style.scss";
// Config
import i18n, { lng } from "../../../engine/config/i18n";
import { axiosAPI } from "../../../engine/config/axios";
import { ActionsRender } from "./components/ActionsRender";
import { ToolbarRender } from "./components/ToolbarRender";
// Engine
import { settingsPostAsync } from "../../../engine/core/tables/saga/asyncAction";
import { setTableGridTitleTooltip } from "../../../engine/core/tables/slice";
import { selectorsTables } from "../../../engine/core/tables/selectors";
import { toast } from "../../../_helpers/toasts";

loadMessages(ruMessages);
loadMessages(ukMessages);
locale(lng);

const pageSizes = [25, 50, 100];
// TS
export type ColumnType = {
  dataField?: string,
  dataType?: string,
  position?: number,
  description?: string,
  actionsList?: string[]
  allowFiltering?: boolean
  filterOperations?: string[],
  caption?: string,
  minWidth?: number,
  width?: number | string,
  visibleIndex?: number,
  columnHeaderFilter?: HeaderFilterType,
  fixed?: boolean,
  field?: string,
  isNotAllowHiding?: boolean,
  allowEditing?: boolean,
  subColumns?: ColumnType[]
};

export type HeaderFilterType = {
  dataSource: FilterType[],
  displayExpr: string,
  valueExpr: string
}

export type FilterType = {
  name: string,
  value: string | number
}

export type TableIdType = { id?: string };
export type TableHashCacheType = { hashCache?: string };
export type TableCanColumnHidingType = { canChangeColumnHiding?: boolean };
export type TableApiParamsType = (loadOptions: LoadOptions<any>, pageParams: {
  offset: number,
  limit: number,
}) => object;
export type ToolbarItemType = {
  name?: string,
  defaultValue?: string | Date | RangeDateDefaultValue,
  values?: string,
  value?: string,
  type: string,
  placeholder?: string,
};
export type RangeDateDefaultValue = {
  dateFrom: string | Date,
  dateTo: string | Date
}
export type ToolbarType = ToolbarItemType[];
type TableType = TableIdType & {
  api: (params: any) => Promise<AxiosResponse<any, any>>,
  apiEdit?: (params: any) => Promise<AxiosResponse<any, any>>,
  apiParams: TableApiParamsType,
  gridName: string,
  keyExpr?: string,
  defaultColumns?: ColumnType[],
  stateStoringEnabled?: boolean,
  toolbar?: ToolbarType,
  gridParams?: {
    grids: { name: string, hashCache?: string, date?: string }[],
    params?: any,
  },
  sortingMode?: "multiple",
  type?: "virtual",
  groupByMonth?: boolean,
  isAllowEditCell?: boolean
};
type DataTableTypes = {
  items: {
    actions: {
      delete: string
    }
  }[],
}

function Table(props: TableType) {
  const dispatch = useDispatch();
  const [loadPanelEnabled, setLoadPanelEnabled] = useState(true);
  const dataGrid = useRef<DataGrid>(null);
  const {
    defaultColumns,
    api,
    apiEdit,
    apiParams,
    keyExpr = 'id',
    toolbar = [],
    sortingMode = 'multiple',
    gridParams,
    gridName,
    id,
    stateStoringEnabled = true,
    type,
    groupByMonth = false,
    isAllowEditCell = false
  } = props;
  const gridDate = useSelector(selectorsTables.grid.settings);
  const gridLoading = useSelector(selectorsTables.grid.loading);
  const languagesForPathSlice = ['ru', 'uk'];
  const pathName = languagesForPathSlice.includes(window.location.pathname.split('/')[1]) ? window.location.pathname.slice(3) : window.location.pathname;
  // const titleTooltip = useSelector(selectorsTables.grid.titleTooltip)?.[gridName]
  const columnHidingEnabled = gridDate?.[gridName].columnHidingEnabled;
  const canChangeColumnHiding = gridDate?.[gridName].canChangeColumnHiding;
  // @ts-ignore-next-line
  const normalizeDefaultColumns: ColumnType[] = useMemo(
    () => gridName && gridDate?.[gridName]?.grid
      ? Object.values(gridDate?.[gridName]?.grid)
      : defaultColumns?.filter(Boolean)
    , [gridDate, gridName, defaultColumns]);
  const actions = useMemo(
    () => filter(normalizeDefaultColumns, (column) => column.dataField === 'actions')[0],
    [normalizeDefaultColumns]
  );
  let dataTable: DataTableTypes = {
    items: []
  };
  const onContentReady = useCallback(() => {
    setLoadPanelEnabled(false);
  }, []);
  const resetFilters = () => {
    dataGrid?.current?.instance.clearFilter();
    resetSort();
  };
  const resetSort = () => {
    dataGrid?.current?.instance.clearSorting();
    resetState();
  };
  const resetState = () => {
    dataGrid?.current?.instance.state(null);
    const timer = setTimeout(() => {
      if (gridParams !== undefined) {
        dispatch(settingsPostAsync({
          ...gridParams,
          forceUpdate: true
        }))
      }
    }, 3000);
    return () => clearTimeout(timer);
  };
  const onStateResetClick = () => {
    resetFilters();
  }

  const onFilterResetClick = () => {
    dataGrid?.current?.instance.clearFilter();
    dataGrid?.current?.instance.clearSorting();
  }

  useEffect(() => {
    if (gridParams !== undefined) {
      dispatch(settingsPostAsync(gridParams))
    }
  }, [dispatch, gridParams]);
  const dataStore = useMemo(() => new DataSource({
    store: new CustomStore({
      key: keyExpr,
      load(loadOptions) {
        let pageParams = {
          offset: loadOptions.skip || 0,
          limit: loadOptions.take || pageSizes[0]
        };
        // @ts-ignore-next-line
        if (loadOptions.isLoadingAll) {
          pageParams = {
            offset: 0,
            limit: Number.MAX_SAFE_INTEGER,
          }
        }

        return api(apiParams(loadOptions, pageParams)).then((response: { data: any; }) => {
          // eslint-disable-next-line no-param-reassign
          response.data.items = response.data.items.map((item: any, index: any) => ({ ...item, OrderIndex: item.positions }));
          return response.data;
        })
          .then((data) => {
            dataTable = data;
            if (data.date_start || data.date_end) {
              dispatch(setTableGridTitleTooltip({
                [gridName]: {
                  type: 'datePeriod',
                  value: {
                    start: data.date_start,
                    end: data.date_end,
                  }
                }
              }))
            }

            return ({
              data: data.items,
              totalCount: data.itemsCount,
              summary: data.summary,
              groupCount: data.groupCount,
            })
          })
          .catch(() => {
            throw new Error('Data Loading Error');
          });
      },
      update: (key, values) => {
        if (apiEdit) {
          apiEdit({ key, values, date: gridParams?.grids[0].date })
            .then(res => res.status === 200 ? toast.show(res.data.text, res.data.title, '', true) : null)
            .catch(() => {
              throw new Error('Data Loading Error');
            })
        }
        return Promise.resolve();
      },
      // @ts-ignore
      remove: (key) => {
        // @ts-ignore
        const item = find(dataTable.items, (itemValues) => itemValues[keyExpr] === key);
        if (item) {
          return axiosAPI._helpers.getAxios({ url: item.actions.delete })
        }
        return null;
      },
    })
  }), [api, apiParams, keyExpr]);
  // @ts-ignore
  const userId = document.querySelector('.js--header')?.dataset?.user_id;
  const storageKey = `${userId}:${!isNaN(parseInt(pathName.substring(pathName.lastIndexOf('/') + 1), 10))
    ? `${pathName.substring(0, pathName.lastIndexOf("/"))}/id`
    : id || pathName}${pathName === '/reports/employees-ratio-per-month' && gridName === 'employeesPerMonthRatioUnit' ? '-unit' : ''}`;

  const onPageSizeChange = (pageSize: number) => {
    dataGrid?.current?.instance.state({
      ...dataGrid?.current?.instance.state(),
      pageIndex: 0,
      pageSize,
    })
  }

  const visibleFilter = useMemo(() => {
    let allowFiltering = false;
    if (normalizeDefaultColumns?.length) {
      const settings = localStorage.getItem(storageKey);
      // Have settings
      if (settings) {
        const visibleColumnsName = JSON.parse(settings)?.columns?.map(
          (item: { visible: boolean; dataField: string; }) =>
            item.visible
              ? item.dataField
              : undefined)
          .filter(Boolean);

        for (const column of normalizeDefaultColumns) {
          if (visibleColumnsName?.includes(column.dataField) && column.filterOperations?.length !== 0) {
            return true;
          }
          allowFiltering = Boolean(column.allowFiltering)
        }
        return allowFiltering;
      }
      // No settings
      for (const column of normalizeDefaultColumns) {
        if (Boolean(column.filterOperations?.length)) {
          return true;
        }
        allowFiltering = Boolean(column.allowFiltering)
      }
      return allowFiltering;
    }
    return allowFiltering;
  }, [normalizeDefaultColumns, storageKey])

  const dataStoreReload = () => {
    dataStore.reload();
  }

  const handleColumnReorder = (columns: ColumnType[]) => {
    if (groupByMonth) {
      columns.map((column: ColumnType) => {
        if (column.dataField?.startsWith('ratioPerMonths_')) {
          // eslint-disable-next-line no-param-reassign
          column.visibleIndex = column.position
        }
      })
    }
  };

  const onChangeRowColor = (e: any) => {
    if (e.data?.actions?.disable) {
      // eslint-disable-next-line no-param-reassign
      e.rowElement.style.backgroundColor = '#67757c1a';
      if (e.cells[0].isAltRow) {
        e.rowElement.classList.remove('dx-row-alt')
      }
    }
  }

  const normalizeColumns = useMemo(
    () => normalizeDefaultColumns?.map(
      (item: ColumnType) => (
          item.dataField === 'actions'
            ? null
            : ColumnRender(item)
        ))
    , [normalizeDefaultColumns]
  )

  return gridLoading && gridName && gridParams ? <div className='card-body'/> : (
    <AutoSizer
      disableHeight={type !== 'virtual'}
      disableWidth={type !== 'virtual'}
    >
      {({ height, width }) => (
        <DataGrid
          columnHidingEnabled={columnHidingEnabled}
          dataSource={dataStore}
          allowColumnResizing
          allowColumnReordering
          showColumnLines
          rowAlternationEnabled
          columnAutoWidth
          columnResizingMode={columnHidingEnabled ? "nextColumn" : "widget"}
          keyExpr={keyExpr}
          remoteOperations
          ref={dataGrid}
          onExporting={(e) => onExporting(e, gridName)}
          height={height}
          width={width}
          customizeColumns={handleColumnReorder}
          onContentReady={onContentReady}
          onRowPrepared={onChangeRowColor}
        >
          <ColumnChooser
            height={550}
            width={300}
            enabled
            allowSearch
            mode="select"
            title={i18n.t('columnChooser').toString()}
          />
          <StateStoring enabled={stateStoringEnabled} type="localStorage" storageKey={storageKey}/>
          {ToolbarRender({
            toolbar,
            onStateResetClick,
            onFilterResetClick,
            gridName,
            canChangeColumnHiding,
            isAllowEditCell
          })}
          {actions && (
            <Editing
              mode='row'
              allowDeleting={actions?.actionsList?.includes("delete")}
              allowUpdating={actions?.actionsList?.includes("edit")}
              confirmDelete
              useIcons
            />
          )}
          {isAllowEditCell ? <Editing mode="batch" allowUpdating/> : null}
          {ActionsRender(actions, dataStoreReload)}
          {normalizeColumns}
          <FilterRow
            visible={visibleFilter}
          />
          <HeaderFilter visible={visibleFilter}/>
          {sortingMode ? (<Sorting mode={sortingMode}/>) : null}
          <Scrolling
            useNative
            mode={type === "virtual" ? "virtual" : "standard"}
            preloadEnabled={type === "virtual"}
            renderAsync={type === "virtual"}/>
          <LoadPanel enabled={loadPanelEnabled}/>
          <Export enabled allowExportSelectedData={false}/>
          <Pager
            visible
            allowedPageSizes={pageSizes}
            showPageSizeSelector
            showInfo
            showNavigationButtons/>
          <Paging onPageSizeChange={onPageSizeChange} defaultPageSize={pageSizes[0]}/>
        </DataGrid>
      )}
    </AutoSizer>
  )
}

export default React.memo(Table);
