// Core
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Checkboxes } from 'mui-rff';
// Engine
import { selectorsDashboard } from "../../../engine/core/dashboard/selectors";
import { setDashBoardCalendarSettingsData } from "../../../engine/core/dashboard/slice";
// Parts
import AutoSave from "../_Form/AutoSave";
// Styles
import useStyles from "./styles";

const Map = () => {
  const dataSettings = useSelector(selectorsDashboard.dashboard.settings.date);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  if (dataSettings.typesAbsences === null) {
    return null;
  }

  const onSubmit = (values: { [key: string]: boolean; }) => {
    const newDataSettings = {
      ...dataSettings,
      typesAbsences: dataSettings.typesAbsences?.map((item: { id: string | number }) => ({
        ...item,
        checked: values[`name_${item.id}`]
      }))
    }
    dispatch(setDashBoardCalendarSettingsData(newDataSettings));
  }

  const initialValues = dataSettings.typesAbsences?.reduce((prevValue, value: { id: string, checked: boolean }) => ({
    ...prevValue,
    [`name_${value.id}`]: value.checked
  }), {});

  const mapItems = dataSettings.typesAbsences?.map((item: { id: number | string, name: string, color: string, checked: boolean, team: { id: number, name: string } }) => {
    const { id, name, color, team } = item;

    if(!team?.id || !dataSettings.selectedTeamsFilter.length || dataSettings.selectedTeamsFilter.includes(String(team?.id))) {
      return (
        <Checkboxes
          key={id}
          size="small"
          name={`name_${id.toString()}`}
          data={{
            label: name,
            value: id.toString(),
          }}
          sx={{
            color,
            '&.Mui-checked': {
              color,
            },
          }}
        />
      )
    }
    return null
  });

  return <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <form className={classes.map} onSubmit={handleSubmit} noValidate>
        {/* @ts-ignore */}
        <AutoSave debounce={1000} save={onSubmit}/>
        <h5 className={classes.mapTitle}>{t('calendars')}</h5>
        {mapItems}
      </form>
    )}
  />

}
export default Map;
