// Style
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    cropper: { height: 200, width: 200, borderRadius: "100%", margin: '0px auto' },
    slider: { width: 200 },
    iconButton: {
      backgroundColor: '#fff',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
      padding: '9px',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
      width: 32,
      height: 32,
      transition: '150ms',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#6c757d',
        '& svg': {
          fill: '#fff',
        },
        boxShadow: '0 4px 14px #a8a8a81f',
      },
    },
    profileInfoPhotoChange: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '-13px',
      right: '10px',
      '& input': {
        display: 'none',
      },
      '& label': {
        padding: 0,
        width: 36,
        height: 36,
      },
      "& svg": {
        width: 18,
        height: 18,
        fill: '#4d4d4d',
      },
    },
    offBg: {
      '& .cropper-bg': {
        background: "none",
      }
    },
    disableCrop: {
      borderRadius: '50%',
      overflow: 'hidden',
      pointerEvents: 'none',
      '& img': {
        opacity: '1 !important',
        minWidth: '100%',
      },
      '& .cropper-modal': {
        opacity: 0,
      },
      '& .cropper-view-box': {
        outline: 'none',
      },
      '& .cropper-line': {
        backgroundColor: 'transparent',
      },
      '& .cropper-point': {
        backgroundColor: 'transparent'
      },
      '& .cropper-center': {
        display: 'none'
      }
    }
  }),
);
