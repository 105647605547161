// Core
import React, { FC } from 'react';
// Engine
// MUI
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import i18n from "../../../../../../engine/config/i18n";

type SaveType = { data: { options: { onClick: () => void, disabled?: boolean } } };

export const Save:FC<SaveType> = (props) => {
  const { data } = props;
  return <Tooltip title={i18n.t('save').toString()} enterTouchDelay={0}>
    <div>
      <Button
        aria-label={i18n.t('save')}
        onClick={data.options.onClick}
        disabled={data.options.disabled}
        variant="contained"
        endIcon={<SaveIcon fontSize="small"/>}
      >
        {i18n.t('save')}
      </Button>
    </div>
  </Tooltip>
};
