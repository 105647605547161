// Core
import React, { FC, ReactElement, useState } from 'react';
import Draggable from 'react-draggable';
// UI
import CloseIcon from '@mui/icons-material/Close';
import DialogUI from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
// Parts
import { IconButton } from "./IconButton";
import { ToolbarItemType } from "./Table";

function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}

const Dialog: FC<{ item: ToolbarItemType, iconButton?: ReactElement }> = (props) => {
  const { children, item, iconButton } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return <>
    {iconButton && (
      <IconButton size="small" onClick={handleClickOpen}>
        {iconButton}
      </IconButton>
    )}
    <DialogUI
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogTitle sx={{ cursor: 'move', borderBottom: '1px solid #e0e0e0' }} id="draggable-dialog-title">
        {item.placeholder}
      </DialogTitle>
      <DialogContent>
        <IconButton
          sx={{
            position: 'absolute',
            right: 6,
            top: 12,
          }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
        {children}
      </DialogContent>
    </DialogUI>
  </>;
};

export default Dialog;
