// Core
import React from 'react';
import axios from 'axios';
import { createPortal } from 'react-dom';
// Parts
import PhotoCropper from "../../components/PhotoCropper";
import { dataURLtoFile } from "../../../_helpers/dataURLtoFile";

const handleCropper = (value: any) => {
  const file = dataURLtoFile(value.image, 'avatar.jpg');
  const formData = new FormData();
  formData.append("avatarImage", file);
  const Root = document.getElementById('react_container__portal--photocropper');
  // @ts-ignore
  const url:any = `${Root?.dataset.actionUrl}`
  const user:any = `${Root?.dataset.user}`
  formData.append("user", user);
  // @ts-ignore
  axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
}

function PhotoCropperPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<PhotoCropper onSend={handleCropper} currentImg={Root?.dataset.src} />, Root) : null;
}

export default PhotoCropperPortal;
