// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call } from 'redux-saga/effects';
// Watchers
import { watchersDashboard } from '../core/dashboard/saga/watcher';
import { watchersTables } from '../core/tables/saga/watcher';

export function* rootSaga(): SagaIterator {
  yield all([
    call(watchersDashboard),
    call(watchersTables)
  ]);
}
