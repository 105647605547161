// Core
import React, { Fragment } from "react";
import fontColorContrast from "font-color-contrast";
import { useTranslation } from "react-i18next";
import ReactJson from 'react-json-view';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
// Parts
import CopyToClipboard from "../../../../../CopyToClipboard";
import { tableColumnsProjects } from "../../../../../../portals/Projects/tableColumns";
import unknownMan from "./img/unknown-man.png";
import unknownGirl from "./img/unknown-girl.png";
import useStyles from './styles';
// Helpers
import { formatDate } from "../../../../../../../_helpers/formatDate";

type ActionsDataType = {
  disable: boolean
}

type PropsType = {
  value: any,
  data: {
    gender: string,
    fired?: boolean,
    hired?: boolean,
    bold?: boolean,
    color?: string,
    actions?: ActionsDataType
  },
  column: {
    dataField?: string,
    dataType: string,
    checkUserStatus?: boolean,
  }
}

export function formattedDate(value: string | number | Date) {
  if (value instanceof Date) {
    return formatDate(value, 'dd.MM.yyyy')
  }
  if (typeof value === 'object' && value !== null) {
    const { start, end, allDay } = value;
    const formatStartDate = formatDate(start, 'dd.MM.yyyy');
    const formatEndDate = formatDate(end, 'dd.MM.yyyy');
    if (allDay) {
      return formatStartDate === formatEndDate ? formatStartDate : `${formatStartDate}-${formatEndDate}`
    }
    return formatStartDate === formatEndDate
      ? `${formatDate(start, 'dd.MM.yyyy HH:mm')}-${formatDate(end, 'HH:mm')}`
      : `${formatDate(start, 'dd.MM.yyyy HH:mm')}-${formatDate(end, 'dd.MM.yyyy HH:mm')}`;
  }
  return value;
}

function CellRender (props: PropsType) {
  const { value, column, data } = props;
  const classes = useStyles();
  // @ts-ignore
  const { i18n: { language } }: { i18n: { language: "ru" | "en" } } = useTranslation();

  if (!value && value !== 0) {
    return null;
  }

  switch (column.dataType) {
    case 'date': {
      return formattedDate(value);
    }
    case 'email': {
      return <><a href={`mailto:${value}`}>{value}</a><CopyToClipboard link={value} /></>;
    }
  }
  if (column?.checkUserStatus) {
    if(column?.dataField === 'user' || column?.dataField === 'user_latin' || column?.dataField === 'initiator') {
      const Content = <>
        <img
          alt=""
          src={value?.avatar?.['40x40']?.src || (value.gender === "f" ? unknownGirl : unknownMan)}
          width={40}
          height={40}
          className={classes.avatar}
        />
        {value.mood !== undefined && <span
          className={classes.mood}
          title={`${value?.mood?.reaction}, ${value?.mood?.date}`}
        >
          {value?.mood?.emoji}
        </span>}
        <span className={classes.info}>
            <h5 className={clsx(classes.name, {
              [classes.nameHired] : data?.hired && !data?.fired,
              [classes.nameFired] : data?.fired && !data?.hired,
              [classes.nameHiredFired] : data?.fired && data?.hired,
            }
              )}>{value?.name}</h5>
            <span className={classes.position}>{value?.position}</span>
        </span>
      </>
      if (value?.user_url !== null) {
        return <a
          href={value?.user_url}
          style={{ pointerEvents: data.actions?.disable ? "none" : 'auto' }}
          className={`dx_global_user ${classes.user}`}
        >
          {Content}
        </a>;
      }
      return <span className={`dx_global_user ${classes.user}`}>{Content}</span>
    }
  }

  switch (column?.dataField) {
    case 'emailPersonal':
    case 'fromEmail':
    case 'emailWork': {
      return <div className={classes.emailWrap}><span className={classes.overflowText}>{value}</span><CopyToClipboard content link={value} /></div>;
    }
    case 'tariff': {
      return <span className={classes.overflowText}>{value.title}</span>;
    }
    case 'registration_url': {
      return <CopyToClipboard link={value} />;
    }
    case 'url' : {
      return Object.keys(value).map((key, index) =>
        <Fragment key={key}>
          <a href={value[key].url} target="_blank" rel="noreferrer">{value[key].text}</a>
          {Object.keys(value).length > 1 && <br/>}
        </Fragment>);
    }
    case 'dayoffType':
    case 'dataField': {
      const styles = {
        backgroundColor: value?.color,
        color: fontColorContrast(value?.color, 0.7)
      }
      return <span className="badge" style={styles}>{value?.title}</span>;
    }
    case 'type': {
      if (typeof value === "string") {
        return value;
      }
      const styles = {
        backgroundColor: value?.mode,
        color: fontColorContrast(value?.mode, 0.7)
      }
      return <span className='badge' style={styles}>{value?.text}</span>
    }
    case 'color':
    case 'connected': {
      const styles = {
        backgroundColor: value?.mode,
        color: fontColorContrast(value?.mode, 0.7)
      }
      return <span className='badge' style={styles}>{value?.text}</span>
    }
    case tableColumnsProjects[language].project_url.dataField: {
      return Object.keys(value).map((key, index) => (
        <Fragment key={key}>
          <a href={value[key].url} target="_blank" rel="noreferrer">{value[key].text}</a>
          {Object.keys(value).length > 1 && <br/>}
        </Fragment>
      ));
    }
    case 'location': {
      const date = `${formatDate(value.date, 'dd.MM.yyyy HH:mm')}`
      const Content = value.title !== null && <span className={classes.location}>{value?.title}
        <i className='fa fa-circle-info' title={date}/>
      </span>
      return Content
    }
    case 'userEdited':
    case 'user_latin':
    case 'initiator':
    case 'teamLeader':
    case 'userStartedBench':
    case 'userEndedBench':
    case 'user': {
      const Content = <>
        <img
          alt=""
          src={value?.avatar?.['40x40']?.src || (value.gender === "f" ? unknownGirl : unknownMan)}
          width={40}
          height={40}
          className={classes.avatar}
        />
        {value.mood !== undefined &&
        <Tooltip title={`${value?.mood?.reaction}, ${value?.mood?.date}`} enterTouchDelay={0}>
        <span
          className={classes.mood}
        >
          {value?.mood?.emoji}
        </span>
        </Tooltip>}
        <span className={classes.info}>
            <h5 className={classes.name}>{value?.name}</h5>
            <span className={classes.position}>{value?.position}</span>
        </span>
      </>
      if (value?.user_url !== null) {
        const styles = {
          display: 'flex',
          alignItems: 'flex-start'
        }
        return (<div style={styles}>
            <a
              href={value?.user_url}
              style={{ pointerEvents: data.actions?.disable ? "none" : 'auto' }}
              className={`dx_global_user ${classes.user}`}
          >
            {Content}
          </a>
          <CopyToClipboard content link={value?.name} />
        </div>);
      }
      return <span className={`dx_global_user ${classes.user}`}>{Content}</span>
    }
    case 'event': {
      return value?.name
    }
    case 'skype': {
      return <a href={`skype:${value}?chat`}>{value}</a>
    }
    case 'linkedin':
    case 'facebook': {
      return <a href={`${value}`}>{value}</a>
    }
    case 'phone': {
      return <a href={`tel:${value}`}>{value}</a>
    }
    case 'attributes': {
      return value ?  (
        <ReactJson
          collapsed
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          name={null}
          src={JSON.parse(value)}
          theme="summerfruit:inverted"
          style={{ backgroundColor: 'transparent' }}
        />
      ) : undefined
    }
    default: {
      if (typeof value === "number" || typeof value === "string") {
        if (data.bold) {
          return <span className={classes.overflowText}><strong>{value}</strong></span>;
        }
        return <span className={classes.overflowText}>{value}</span>;
      }
      return null;
    }
  }
}

export default CellRender;
