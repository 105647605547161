// Core
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
// styles
import { ThemeProvider } from '@mui/material/styles';
import '../../engine/config/i18n';
import "./style.scss"
// Engine
import { store } from '../../engine/init/store';
// Parts
import TeamsTablePortal from '../portals/TeamsTable';
import EmployeePortal from '../portals/Employee';
import BenchPortal from '../portals/Bench/Bench';
import ProjectTimeMonthPortal from "../portals/ProjectTimeMonth/ProjectTimeMonth";
import ProjectTimeMonthLogPortal from "../portals/ProjectTimeMonth/ProjectTimeMonthLog";
import {
  ProjectsPortal, ProjectsElapsedPortal, ProjectTrackedTimePortal,
  ProjectTrackedTimeEmployeesPortal, UserProjectsPortal
} from '../portals/Projects';
import RegisteredUsers from '../portals/RegisteredUsers';
import PhotoCropperPortal from '../portals/CropPhoto';
import DashboardPortal from '../portals/Dashboard';
import HolidaysPortal from '../portals/Holidays';
import OvertimesPortal from '../portals/Overtimes';
import {
  PositionsPortal, VacationTypesPortal, EmployeeTypesPortal,
  TrackersPortal, MailTemplatesPortal, LeaveReasonsTablePortal,
  CurrenciesTablePortal, CompanyLinksTablePortal, WidgetsPortal,
  EventsPortal, MailChainCategoriesPortal, MailChainsPortal, SsoGroupsPortal
, SsoGroupEmployeesPortal } from '../portals/Settings';
import { VacationBalanceTablePortal, VacationTablePortal, VacationBalanceHistoryTablePortal } from '../portals/Vacation';
import {
  AbsenceTablePortal, ReportsOvertimesPortal, PerformanceTablePortal,
  LoggedTimeTablePortal, EmployeesRatioPerMonthTablePortal, HoursUtilizationTablePortal,
  StatisticsByTeamsChart1Portal, StatisticsByTeamsChart2Portal,
  StatisticsByTeamsChart3Portal, StatisticsByTeamsChart4Portal,
  StatisticsByTeamsChart5Portal, StatisticsByTeamsChart6Portal, AttritionRateTablePortal
} from '../portals/Reports';
import SubscriptionsListPortal from '../portals/SubscriptionsList';
import { UserCompensationsTablePortal } from '../portals/UserCompensations';
// Config
import theme from '../../engine/config/theme';

function App() {
  return <Provider store={store}>
    <ThemeProvider theme={theme}>
        <Suspense fallback={<>Loading...</>}>
          <TeamsTablePortal id="react_container__portal--teams_table" />
          <EmployeePortal id="react_container__portal--employee" />
          <ProjectsPortal id="react_container__portal--projects" />
          <ProjectsElapsedPortal id="react_container__portal--project_statistics" />
          <RegisteredUsers id="react_container__portal--registered_users" />
          <PhotoCropperPortal id="react_container__portal--photocropper" />
          <DashboardPortal id="react_container__portal--dashboard_scheduler" />
          <VacationTablePortal id="react_container__portal--vacation" />
          <VacationBalanceTablePortal id="react_container__portal--vacation_balance" />
          <VacationBalanceHistoryTablePortal id="react_container__portal--vacation_balance_history" />
          <HolidaysPortal id="react_container__portal--holidays" />
          <OvertimesPortal id="react_container__portal--overtimes" />
          <PositionsPortal id="react_container__portal--positions" />
          <VacationTypesPortal id="react_container__portal--vacation_types" />
          <EmployeeTypesPortal id="react_container__portal--employees_types" />
          <TrackersPortal id="react_container__portal--trackers" />
          <MailTemplatesPortal id="react_container__portal--mail_templates" />
          <SsoGroupsPortal id="react_container__portal--sso_groups" />
          <SsoGroupEmployeesPortal id="react_container__portal--sso_group_employees" />
          <ReportsOvertimesPortal id="react_container__portal--reports_overtimes" />
          <StatisticsByTeamsChart1Portal id="react_container__portal--reports_statistics_by_teams_chart_1" />
          <StatisticsByTeamsChart2Portal id="react_container__portal--reports_statistics_by_teams_chart_2" />
          <StatisticsByTeamsChart3Portal id="react_container__portal--reports_statistics_by_teams_chart_3" />
          <StatisticsByTeamsChart4Portal id="react_container__portal--reports_statistics_by_teams_chart_4" />
          <StatisticsByTeamsChart5Portal id="react_container__portal--reports_statistics_by_teams_chart_5" />
          <StatisticsByTeamsChart6Portal id="react_container__portal--reports_statistics_by_teams_chart_6" />
          <AbsenceTablePortal id="react_container__portal--reports_absence" />
          <SubscriptionsListPortal id="react_container__portal--subscriptions_list" />
          <LoggedTimeTablePortal id="react_container__portal--reports_logged_time" />
          <PerformanceTablePortal id="react_container__portal--reports_performance" />
          <EmployeesRatioPerMonthTablePortal id="react_container__portal--reports_employees_per_month" />
          <AttritionRateTablePortal id="react_container__portal--reports_attrition_rate" />
          <UserCompensationsTablePortal id="react_container__portal--user_compensations" />
          <LeaveReasonsTablePortal id="react_container__portal--user_leave_reason" />
          <CurrenciesTablePortal id="react_container__portal--currencies" />
          <ProjectTrackedTimePortal id="react_container__portal--project_positions" />
          <ProjectTrackedTimeEmployeesPortal id="react_container__portal--project_employees" />
          <CompanyLinksTablePortal id="react_container__portal--company_links" />
          <WidgetsPortal id="react_container__portal--widgets" />
          <HoursUtilizationTablePortal id="react_container__portal--reports_hours_utilization" />
          <EventsPortal id="react_container__portal--events" />
          <MailChainCategoriesPortal id="react_container__portal--mail-chain-categories" />
          <MailChainsPortal id="react_container__portal--mail-chains" />
          <UserProjectsPortal id="react_container__portal--userProjects" />
          <BenchPortal id="react_container__portal--bench" />
          <ProjectTimeMonthPortal id="react_container__portal--project_time_month" />
          <ProjectTimeMonthLogPortal id="react_container__portal--project_time_month_log" />
        </Suspense>
    </ThemeProvider>
  </Provider>;
}

export default App;
