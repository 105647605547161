// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const Bench:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  const gridParams = {
    grids: [{ name: 'bench', hashCache }],
  }

  return <Table
    api={axiosAPI.bench.table}
    apiParams={apiParams}
    gridName="bench"
    gridParams={gridParams}
    sortingMode="multiple"
  />;
}

function EmployeePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);

  return Root ? createPortal(<Bench hashCache={Root?.dataset.hashCache}/>, Root) : null;
}

export default EmployeePortal;
