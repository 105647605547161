import React, { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface Loader {
  fill?: string;
}

const Loader: FC<Loader> = ({ fill }) => (
  <SvgIcon viewBox="0 0 28 28">
    <path
      opacity="0.2"
      d="M21.8579 17.5912C23.0409 17.5912 23.9999 16.6322 23.9999 15.4492C23.9999 14.2661 23.0409 13.3071 21.8579 13.3071C20.6748 13.3071 19.7158 14.2661 19.7158 15.4492C19.7158 16.6322 20.6748 17.5912 21.8579 17.5912Z"
      fill={fill}
    />
    <path
      opacity="0.6"
      d="M2.14203 17.5912C3.32504 17.5912 4.28406 16.6322 4.28406 15.4492C4.28406 14.2661 3.32504 13.3071 2.14203 13.3071C0.959021 13.3071 0 14.2661 0 15.4492C0 16.6322 0.959021 17.5912 2.14203 17.5912Z"
      fill={fill}
    />
    <path
      opacity="0.4"
      d="M11.9999 27.4491C13.183 27.4491 14.142 26.4901 14.142 25.3071C14.142 24.1241 13.183 23.165 11.9999 23.165C10.8169 23.165 9.85791 24.1241 9.85791 25.3071C9.85791 26.4901 10.8169 27.4491 11.9999 27.4491Z"
      fill={fill}
    />
    <path
      opacity="0.8"
      d="M12.0002 8.19385C13.4345 8.19385 14.5972 7.03117 14.5972 5.59693C14.5972 4.16268 13.4345 3 12.0002 3C10.566 3 9.40332 4.16268 9.40332 5.59693C9.40332 7.03117 10.566 8.19385 12.0002 8.19385Z"
      fill={fill}
    />
    <path
      opacity="0.3"
      d="M18.9672 24.5643C20.1502 24.5643 21.1093 23.6053 21.1093 22.4223C21.1093 21.2393 20.1502 20.2803 18.9672 20.2803C17.7842 20.2803 16.8252 21.2393 16.8252 22.4223C16.8252 23.6053 17.7842 24.5643 18.9672 24.5643Z"
      fill={fill}
    />
    <path
      opacity="0.7"
      d="M5.03217 10.6239C6.21518 10.6239 7.1742 9.66489 7.1742 8.48188C7.1742 7.29886 6.21518 6.33984 5.03217 6.33984C3.84916 6.33984 2.89014 7.29886 2.89014 8.48188C2.89014 9.66489 3.84916 10.6239 5.03217 10.6239Z"
      fill={fill}
    />
    <path
      opacity="0.5"
      d="M5.03266 24.5643C6.21567 24.5643 7.17469 23.6053 7.17469 22.4223C7.17469 21.2393 6.21567 20.2803 5.03266 20.2803C3.84965 20.2803 2.89062 21.2393 2.89062 22.4223C2.89062 23.6053 3.84965 24.5643 5.03266 24.5643Z"
      fill={fill}
    />
    <path
      d="M18.9671 11.2168C20.4776 11.2168 21.7022 9.99227 21.7022 8.4817C21.7022 6.97114 20.4776 5.74658 18.9671 5.74658C17.4565 5.74658 16.2319 6.97114 16.2319 8.4817C16.2319 9.99227 17.4565 11.2168 18.9671 11.2168Z"
      fill={fill}
    />
  </SvgIcon>
);

Loader.defaultProps = {
  fill: "white",
};

export default Loader;
