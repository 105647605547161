// Core
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// DX
import {
  Toolbar,
  Item,
} from 'devextreme-react/data-grid';
// MUI
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// Parts
import { TableCanColumnHidingType, ToolbarItemType, ToolbarType } from "../../index";
import { formatDate } from "../../../../../_helpers/formatDate";
import i18n from "../../../../../engine/config/i18n";
// Components
import AntSwitch from '../../../_Form/AntSwitch';
import RangeDataPicker from './components/RangeDatePicker';
import DataPicker from './components/DatePicker';
import ExportingComponent from "./components/Exporting";
import Select from './components/Select';
import { IconButton } from '../../../IconButton';
import { ColumnChooser } from "./components/ColumnChooser";
import { Save } from "./components/Save";
import { ResetEditing } from "./components/ResetEditing";
// Hooks
import { useMediaDownMd } from "../../../../hooks/useDesktopView";
// Engine
import { selectorsTables } from "../../../../../engine/core/tables/selectors";
import { tableSettingsColumnHidingEnabledAsync } from "../../../../../engine/core/tables/saga/asyncAction";
// TS
type GridNameType = string
type TooltipInfoItemType = {
  gridName: GridNameType
}
type ToolbarRenderType = {
  toolbar: ToolbarType,
  gridName: GridNameType
  onStateResetClick: () => void,
  onFilterResetClick: () => void,
  isAllowEditCell?: boolean
} & TableCanColumnHidingType

const TooltipInfoItem: FC<TooltipInfoItemType> = (props) => {
  const { gridName } = props;
  const isMobile = useMediaDownMd();
  const titleTooltip = useSelector(selectorsTables.grid.titleTooltip)?.[gridName]
  if (titleTooltip === undefined) {
    return null
  }
  switch (titleTooltip.type) {
    case 'datePeriod': {
      const content = titleTooltip.value ? `${formatDate(titleTooltip.value.start)}–${formatDate(titleTooltip.value.end)}` : '';
      return isMobile ? <Tooltip title={content} enterTouchDelay={0}>
        <IconButton size="small">
          <InfoIcon fontSize="inherit"/>
        </IconButton>
      </Tooltip> : <span> {content}</span>
    }
    default: {
      return null;
    }
  }
}

const ToolbarColumnHidingEnabledItem: FC<TooltipInfoItemType> = (props) => {
  const { gridName } = props;
  const dispatch = useDispatch();
  const gridDate = useSelector(selectorsTables.grid.settings);
  const columnHidingEnabled = gridDate?.[gridName].columnHidingEnabled;
  const handleChange = (status: boolean) => {
    dispatch(tableSettingsColumnHidingEnabledAsync({
      status,
      gridName
    }))
  }
  return (
    <Tooltip title={i18n.t('adaptiveTable').toString()} enterTouchDelay={0}>
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch handleChange={handleChange} checked={columnHidingEnabled}/>
      </Stack>
    </Tooltip>
  )
}

export const ToolbarRender: FC<ToolbarRenderType> = (props) => {
  const { toolbar, onStateResetClick, onFilterResetClick, gridName, canChangeColumnHiding, isAllowEditCell } = props;
  const isTableReportLoggedTime = (gridName === 'loggedTimePosition' || gridName === 'loggedTimeEmployee');

  return (
    <Toolbar>
      {toolbar?.map((item: ToolbarItemType) => {
        const { name = '' } = item;
        switch (item.type) {
          case 'title': {
            return <Item key={item.name} location="before">
              <b>{item.value}</b>
              <TooltipInfoItem gridName={gridName}/>
            </Item>
          }
          case 'select': {
            return (
              <Item key={item.name} location="after">
                <Select item={item} name={name}/>
              </Item>
            )
          }
          case 'datepicker': {
            return (
              <Item key={item.name} location="after">
                <DataPicker item={item} name={name}/>
              </Item>
            )
          }
          case 'rangedatepicker': {
            return (
              <Item key={item.name} location="after">
                <RangeDataPicker item={item} name={name}/>
              </Item>
            )
          }
          default: {
            return null
          }
        }
      })}
      {isAllowEditCell ?
        <Item
          name="saveButton"
          component={Save}
          location="before"
        /> : null
      }
      {isAllowEditCell ?
        <Item
          name="revertButton"
          component={ResetEditing}
          location="before"
        />
          : null
      }
      {onFilterResetClick && !isTableReportLoggedTime && (
        <Item key="resetFiltersAndSorting">
          <Tooltip title={i18n.t('reset.filtersAndSorting').toString()} enterTouchDelay={0}>
            <IconButton
              aria-label={i18n.t('reset.filtersAndSorting')}
              size="small"
              onClick={onFilterResetClick}>
              <FilterAltOffIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </Item>
      )}
      {onStateResetClick && (
        <Item key="resetSettings">
          <Tooltip title={i18n.t('reset.settings').toString()} enterTouchDelay={0}>
            <IconButton
              aria-label={i18n.t('reset.settings')}
              size="small"
              onClick={onStateResetClick}>
              <SettingsBackupRestoreIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </Item>
      )}
      <Item
        name="exportButton"
        component={ExportingComponent}
      />
      <Item
        name="columnChooserButton"
        component={ColumnChooser}
      />
      {canChangeColumnHiding
        ? (
          <Item key="responsive" location="after">
            <ToolbarColumnHidingEnabledItem gridName={gridName}/>
          </Item>
        )
        : null
      }
    </Toolbar>
  )
}
