// @ts-ignore
import Swal from 'sweetalert2/dist/sweetalert2.js'
// Engine
// import i18n from "../../engine/config/i18n";
// Parts
import './style.scss'

export const toast = {
  show: (text: string, title: string, icon: string, isSuccess: boolean, reload = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showCloseButton: true,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      customClass: {
        timerProgressBar: isSuccess ? 'success' : 'error'
      },
      didOpen: (toastContent: any) => {
        toastContent.addEventListener('mouseenter', Swal.stopTimer)
        toastContent.addEventListener('mouseleave', Swal.resumeTimer)
      },
      willClose: () => {
        if (reload) {
          document.location.reload()
        }
      }
    })

    Toast.fire({
      icon,
      title,
      text,
      isSuccess,
    })
  }
}
