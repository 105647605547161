// Engine
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from "../../engine/config/theme";

export function useMediaUpLg() {
  return !useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
}

export function useMediaDownLg() {
  return useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
}

export function useMediaUpMd() {
  return !useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
}
export function useMediaDownMd() {
  return useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
}
