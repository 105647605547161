// Engine
import { RootState } from '../../init/store';

export const selectorsTables = Object.freeze({
  grid: {
    loading: (state: RootState) => state.tables.grid.loading,
    titleTooltip: (state: RootState) => state.tables.grid.titleTooltip,
    settings: (state: RootState) => state.tables.grid.settings,
  }
});
