// Core
import { configureStore } from '@reduxjs/toolkit';

// Instruments
import { sagaMiddleWare, middleware } from './midlware';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleWare.run(rootSaga);
