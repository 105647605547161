// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const HoursUtilizationTable: FC<{ toolbar: ToolbarType }> = (props) => {
  const { toolbar } = props;
  const params = new URLSearchParams(window.location.search)

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    team: params.get('team'),
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name : 'hoursUtilization' }],
  }

  return <Table
    toolbar={toolbar}
    api={axiosAPI.reports.hoursUtilization}
    gridParams={gridParams}
    gridName="hoursUtilization"
    apiParams={apiParams}
  />;
}

function HoursUtilizationTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Hours utilization rate')
  }];
  const Root = document.getElementById(id);
  return Root ? createPortal(<HoursUtilizationTable toolbar={toolbar}/>, Root) : null;
}

export default HoursUtilizationTablePortal;
