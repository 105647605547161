// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const MailChains:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;
  const gridParams = {
    grids: [{ name: 'mailChain', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  return <Table
    api={axiosAPI.settings.mailChains.table}
    apiParams={apiParams}
    gridParams={gridParams}
    gridName="mailChain"
    sortingMode="multiple"
  />;
}

function MailChainsPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<MailChains hashCache={Root?.dataset.hashCache} />, Root) : null;
}

export default MailChainsPortal;
