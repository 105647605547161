import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
import App from './ui/containers/App';
import reportWebVitals from './reportWebVitals';
import initSentry from './initSentry'

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('react--root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
