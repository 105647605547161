// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';

// Parts
import { actionsGetPostAsync, settingsPostAsync, tableSettingsColumnHidingEnabledAsync } from './asyncAction';

// Workers
import {
  callActionsTableGetPostWorker,
  callTableSettingsPostWorker,
  callTableSettingsColumnHidingEnabledWorker
} from './workers';

function* watchActionsTableDelete() {
  yield takeLatest(actionsGetPostAsync.type, callActionsTableGetPostWorker);
}
function* watchSettingsPost() {
  yield takeLatest(settingsPostAsync.type, callTableSettingsPostWorker);
}
function* watchActionsTableSettingsColumnHidingEnabledAsync() {
  yield takeLatest(tableSettingsColumnHidingEnabledAsync.type, callTableSettingsColumnHidingEnabledWorker);
}

export function* watchersTables(): SagaIterator {
  yield all([
    call(watchActionsTableDelete),
    call(watchSettingsPost),
    call(watchActionsTableSettingsColumnHidingEnabledAsync),
  ]);
}
