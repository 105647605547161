// Core
import React, { FC, useRef, useState, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

// UI
import LinkIcon from '@mui/icons-material/Link';
import ContentCopy from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Style
const useStyles = makeStyles(() =>
  createStyles({
    copyIcon: {
      fontSize: '0.7em',
    },
  }),
);

type CopyToClipboardComponentTypes = {
  link: string,
  content?: boolean,
};
const CopyToClipboardComponent:FC<CopyToClipboardComponentTypes> = (props) => {
  const {
    link,
    content,
  } = props;
  const { t } = useTranslation();
  const [linkText, setLinkText] = useState(content ? t("copy.text") : t("copy.link"));
  const timerToClearSomewhere = useRef<null | ReturnType<typeof setTimeout>>(null);
  const classNames = useStyles();

  const onCopy = () => {
    copy(link);
    setLinkText(t('done'));
    timerToClearSomewhere.current = setTimeout(() => setLinkText(content ? t("copy.text") : t("copy.link")), 5000);
  };

  return (
    <Tooltip title={linkText} onClick={onCopy} enterTouchDelay={0}>
      <IconButton>
        {content ?
          <ContentCopy className={classNames.copyIcon} color="primary" />
        : <LinkIcon color="primary" />
        }

      </IconButton>
    </Tooltip>
  );
}

export default memo(CopyToClipboardComponent);
