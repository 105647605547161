// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const RegisteredUsers:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;
  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  const gridParams = {
    grids: [{ name: 'newcomers', hashCache }],
  }
  return <Table
    api={axiosAPI.registeredUsers.table}
    apiParams={apiParams}
    gridName="newcomers"
    gridParams={gridParams}
    sortingMode="multiple"
  />;
}

function RegisteredUsersPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);

  return Root ? createPortal(<RegisteredUsers hashCache={Root?.dataset.hashCache}  />, Root) : null;
}

export default RegisteredUsersPortal;
