// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { ChartsBar } from "../../../components/Charts";

const EmployeesWorkingToday: FC = () => {
  const params = new URLSearchParams(window.location.search)

  const apiParams = {
    name: "employees_start_count",
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    filters: {
      team: params.getAll('team[]').filter(item => item !== 'all'),
    }
  };

  return <ChartsBar
    apiParams={apiParams}
  />;
}

function StatisticsByTeamsChart6Portal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<EmployeesWorkingToday/>, Root) : null;
}

export default StatisticsByTeamsChart6Portal;
