export const tableColumns = {
  ru: {
    actions: {
      id: 1,
      dataField: 'actions',
      caption: 'Утв.',
      actionsList: ['edit', 'delete'],
    },
    user: {
      id: 2,
      dataField: 'user',
      dataType: 'string',
      caption: 'Сотрудник',
      allowSorting: true,
      filterOperations: ['contains'],
    },
    total: {
      id: 3,
      dataField: 'total',
      caption: 'Общее время',
      dataType: 'string',
      allowSorting: true,
      filterOperations: [],
      // filterOperations: ['contains'],
    },
  },
  en: {
    actions: {
      id: 1,
      dataField: 'actions',
      caption: 'Appr.',
      actionsList: ['edit', 'delete'],
    },
    user: {
      id: 2,
      dataField: 'user',
      dataType: 'string',
      caption: 'Employee',
      allowSorting: true,
      filterOperations: ['contains'],
    },
    total: {
      id: 3,
      dataField: 'total',
      caption: 'Total time',
      dataType: 'string',
      allowSorting: true,
      filterOperations: [],
      // filterOperations: ['contains'],
    },
  },
  uk: {
    actions: {
      id: 1,
      dataField: 'actions',
      caption: 'Затв.',
      actionsList: ['edit', 'delete'],
    },
    user: {
      id: 2,
      dataField: 'user',
      dataType: 'string',
      caption: 'Співробітник',
      allowSorting: true,
      filterOperations: ['contains'],
    },
    total: {
      id: 3,
      dataField: 'total',
      caption: 'Загальний час',
      dataType: 'string',
      allowSorting: true,
      filterOperations: [],
      // filterOperations: ['contains'],
    },
  },
}
