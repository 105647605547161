// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import { useTranslation } from "react-i18next";
import Table, { TableApiParamsType, TableCanColumnHidingType, TableHashCacheType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const params = new URLSearchParams(window.location.search)

const ProjectTimeMonth:FC<TableHashCacheType & TableCanColumnHidingType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache, canChangeColumnHiding } = props;

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      date: params.get('date'),
      project: params.get('project'),
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  const gridParams = {
    grids: [{
      name: 'projectTimeMonth',
      hashCache,
      canChangeColumnHiding,
      date: params.get('date') || '',
      project: params.get('project') || ''
    }],
  }

  return <Table
    toolbar={toolbar}
    api={axiosAPI.projectTimeMonth.table}
    apiEdit={axiosAPI.projectTimeMonth.edit}
    apiParams={apiParams}
    gridName="projectTimeMonth"
    gridParams={gridParams}
    isAllowEditCell
  />;
}

function ProjectTimeMonthPortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Month Timesheet')
  }];

  const Root = document.getElementById(id);


  return Root ? createPortal(<ProjectTimeMonth hashCache={Root?.dataset.hashCache} canChangeColumnHiding={false} toolbar={toolbar}/>, Root) : null;
}

export default ProjectTimeMonthPortal;
