// Core
import { SagaIterator } from '@redux-saga/core';
import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { tablesActionsGetPostAsyncTypes } from "../asyncAction";

export function* callActionsTableGetPostWorker(action: PayloadAction<tablesActionsGetPostAsyncTypes>): SagaIterator {
  const { payload: { url, method = 'GET', dataReload, data  } } = action;
  try {
     method === 'GET'
      ? yield call(axiosAPI._helpers.getAxios, { url })
      : yield call(axiosAPI._helpers.postAxios, { url, data });
  } catch (error) {
    console.warn(error);
  }
  finally {
    if (dataReload) {
      yield call(dataReload);
    }
  }
}
