// Core
import { SagaIterator } from '@redux-saga/core';
import { put, call } from 'redux-saga/effects';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setDashBoardCalendarSettingsData, setDashBoardCalendarSettingsLoading } from '../../slice';

export function* callGetDashboardCalendarSettingsWorker(): SagaIterator {
  yield put(setDashBoardCalendarSettingsLoading(true));
  try {
    const { data } = yield call(axiosAPI.dashboard.calendar.settings);
    const calendarData = yield call(axiosAPI.dashboard.calendar.data);
    const newDataSettings = {
      typesAbsences: data.map((item: object) => ({
        ...item,
        checked: true,
      })),
      selectedTeamsFilter: [],
      availableTeamsFilter: calendarData.data.availableTeamFilter
    }
    yield put(setDashBoardCalendarSettingsData(newDataSettings));
  } catch (error) {
    console.warn(error);
  }
  yield put(setDashBoardCalendarSettingsLoading(false));
}
