// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const CompanyLinksTable:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;
  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'companyLinks', hashCache }],
  }

  return <Table
    api={axiosAPI.settings.companyLinks}
    gridParams={gridParams}
    gridName="companyLinks"
    apiParams={apiParams}
  />;
}

function CompanyLinksTablePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<CompanyLinksTable hashCache={Root?.dataset.hashCache} />, Root) : null;
}

export default CompanyLinksTablePortal;
