// Core
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// Instruments
const sagaMiddleWare = createSagaMiddleware();
const middleware = [
  sagaMiddleWare,
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }),
];

export { sagaMiddleWare, middleware };
