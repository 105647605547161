import React, { ChangeEvent } from "react";

const paramsURL = new URLSearchParams(window.location.search);
const date = paramsURL.get('date');

type PropsType = {
  dataField?: string,
  caption?: string,
}

export const HeaderCellComponent = (props: PropsType) => {
  const { dataField, caption } = props;
  const storageKey = 'status:project/month-timesheet';

  // Create the project/date key
  const projectDateKey = `${dataField}/${date}`;

  // Retrieve the current status object from localStorage or initialize it
  const storedStatus = localStorage.getItem(storageKey);
  const status = storedStatus ? JSON.parse(storedStatus) : {};

  // Determine if the checkbox should be checked
  const isChecked = status[projectDateKey] === true;

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    const currentStoredStatus = localStorage.getItem(storageKey);
    const currentStatus = currentStoredStatus ? JSON.parse(currentStoredStatus) : {};
    currentStatus[projectDateKey] = value;
    localStorage.setItem(storageKey, JSON.stringify(currentStatus));
  }

  return (
    <label className="custom-control custom-checkbox">
      <input type="checkbox" defaultChecked={isChecked} className="custom-control-input" onChange={handleChecked}/>
      {'\u00A0'}<span className="custom-control-label">{caption}</span>
    </label>
  )
};
