// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const Positions:FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;

  const gridParams = {
    grids: [{ name: 'positions', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  return <Table
    api={axiosAPI.settings.positions.table}
    apiParams={apiParams}
    gridParams={gridParams}
    gridName="positions"
    sortingMode="multiple"
  />;
}

function PositionsPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<Positions hashCache={Root?.dataset.hashCache} />, Root) : null;
}

export default PositionsPortal;
