// Core
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
// DX
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import PieChart, {
  Legend,
  Series,
  Title,
  Subtitle,
  Tooltip,
  Label,
  Export,
} from 'devextreme-react/pie-chart';
// Styles
import useStyles from "../styles";
import { axiosAPI } from "../../../../engine/config/axios";

export const PieBar = (props: { api?: any; apiParams: object; }) => {
  const {
    api = axiosAPI.charts.data,
    apiParams
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ subTitle, title, type = "doughnut", palette = ['#ff0000', '#133fef'], legend = {
    horizontalAlignment: "left",
    verticalAlignment: "top",
    visible: true
  }, ...seriesData }, setMetaData] = useState<{
    color?: string,
    subTitle?: string,
    palette?: string[],
    title?: string,
    type?: "doughnut" | "pie",
    legend?: {
      visible: boolean,
      horizontalAlignment: "right" | "left",
      verticalAlignment: "top" | "bottom",
    },
  }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => { // TODO без лоадера криво расчитывается размер графика
    const timeoutID = setTimeout(() => setLoading(true), 1500);
    return () => clearTimeout(timeoutID );
  }, []);

  const dataSource = useMemo(() => new DataSource({
      store: new CustomStore({
        load: () => (
          api(apiParams)
            .then((response: { data: any; }) => {
              setMetaData(response.data.meta);
              return response.data.data;
            })
            .catch(() => { throw new Error('Data Loading Error'); })
        ),
        loadMode: 'raw',
      }),
      paginate: false,
    }), [api, apiParams]);


  const customizeTooltip = (arg: { percent: number; argumentText: string; value: number;}) => ({
      text: `${arg.argumentText}, ${(arg.percent * 100).toFixed(2)}%, ${arg.value}`,
  });

  const margin = { left: 10, right: 10, bottom: 10, top: 10 };

  return <div className={classes.wrapper}>
    {!loading
      ? <div className={classes.loader}>{`${t('loading')}...`}</div>
      : (
        <PieChart
          type={type}
          sizeGroup="pies"
          dataSource={dataSource}
          palette={palette}
        >
          <Title margin={margin} text={title}>
            <Subtitle text={subTitle}/>
          </Title>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Series {...seriesData}>
            <Label visible />
          </Series>
          <Export enabled/>
          <Legend
            margin={0}
            visible={legend.visible}
            horizontalAlignment='center'
            verticalAlignment='bottom'
          />
          <Tooltip enabled arrowLength={10} customizeTooltip={customizeTooltip} />
        </PieChart>
      )
    }
  </div>
};
