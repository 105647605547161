import * as Sentry from '@sentry/react';

const initSentry = (): void => {
  const sentryIsActive = process.env.REACT_APP_SENTRY === 'true' && process.env.REACT_APP_SENTRY_DSN;
  const sentryReplayIsActive = process.env.REACT_APP_SENTRY_REPLAY === 'true';

  if (sentryIsActive) {
    const integrations = [];
    if (sentryReplayIsActive) {
      integrations.push(new Sentry.Replay());
    }
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      replaysSessionSampleRate: sentryReplayIsActive ? 1.0 : 0,
      replaysOnErrorSampleRate: sentryReplayIsActive ? 1.0 : 0,
      tracesSampleRate: 1.0,
      integrations,
    });
  }
};

export default initSentry;
