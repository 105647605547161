// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const AbsenceTable: FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const absenceParams = {
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    teamIds: params.getAll('teamIds[]'),
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    ...absenceParams,
    ...pageParams,
  });

  const gridParams = {
    grids: [
      {
        name: 'absenceReport', hashCache,
      }
    ],
    params: absenceParams
  }

  return <Table
    toolbar={toolbar}
    api={axiosAPI.reports.absence}
    gridParams={gridParams}
    gridName="absenceReport"
    apiParams={apiParams}
  />;
}

function AbsenceTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Absence')
  }];

  const Root = document.getElementById(id);
  return Root ? createPortal(
    <AbsenceTable
      hashCache={Root?.dataset.hashCache}
      toolbar={toolbar}
    />, Root) : null;
}

export default AbsenceTablePortal;
