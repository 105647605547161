// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

const AttritionRateTable: FC<TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    filters: {
      unit: params.getAll('unit'),
    },
    sort: loadOptions?.sort,
    ratio_per_month: params.get('ratio_per_month') === 'on',
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'attritionPerMonthRatioUnit', hashCache }],
  }

  return <Table
    toolbar={toolbar}
    api={axiosAPI.reports.attritionRate}
    gridParams={gridParams}
    gridName="attritionPerMonthRatioUnit"
    apiParams={apiParams}
  />;
}

function AttritionRateTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();

  const toolbar = [{
    type: 'title',
    value: t('Attrition rate')
  }];
  const Root = document.getElementById(id);
  return Root ? createPortal(<AttritionRateTable hashCache={Root?.dataset.hashCache} toolbar={toolbar}/>, Root) : null;
}

export default AttritionRateTablePortal;
