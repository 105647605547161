// Parts
import { createAction } from '@reduxjs/toolkit';

export type tablesActionsGetPostAsyncTypes = {
  url: string,
  method?: 'GET' | 'POST',
  dataReload?: () => void,
  data?: string
};
export type tablesSettingsPostAsyncTypes = {
  grids: { name: string, hashCache?: string, canChangeColumnHiding?: boolean, date?: string }[],
  params?: any,
  forceUpdate?: boolean,
};
export type tableSettingsColumnHidingEnabledAsyncTypes = {
  gridName: string
  status: boolean
}

const tablesAsyncAction = Object.freeze({
  actionsGetPostAsync: createAction<tablesActionsGetPostAsyncTypes>('TABLES_ACTIONS_GET_POST_ASYNC'),
  settingsPostAsync: createAction<tablesSettingsPostAsyncTypes>('TABLES_SETTINGS_POST_ASYNC'),
  tableSettingsColumnHidingEnabledAsync: createAction<tableSettingsColumnHidingEnabledAsyncTypes>('TABLES_SETTINGS_COLUMN_HIDING_ENABLED_POST_ASYNC'),
});

export const { actionsGetPostAsync, settingsPostAsync, tableSettingsColumnHidingEnabledAsync } = tablesAsyncAction;
