// Core
import localforage from 'localforage';

class LocalData {
  private localForage: any;

  public keysObj: { [key: string]: string };

  constructor() {
    this.localForage = localforage;
    this.keysObj = {
      gridsSettings: 'gridsSettings',
    }
  }

  getItem(key: string) {
    return this.localForage
      .getItem(key)
      .catch(console.warn); // eslint-disable-line no-console
  }

  setItem(key: string, data: any) { // eslint-disable-line
    this.localForage
      .setItem(key, data)
      .catch(console.warn); // eslint-disable-line no-console
  }

  removeItem(key: string, successCallback: ()=> void) {
    this.localForage
      .removeItem(key, successCallback)
      .catch(console.warn); // eslint-disable-line no-console
  }

  clearStorage() {
    this.localForage
      .clear()
      .catch(console.warn); // eslint-disable-line no-console
  }
}

export const localData = new LocalData();
