import React from 'react';
// DX
import { Column } from 'devextreme-react/data-grid';
// MUI
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
// Parts
import CellRender from "./components/CellRender";
import { ColumnType } from "../..";
import { HeaderCellComponent } from "./components/HeaderCaption";

const cellRender = (propsCell: any) => (
  <div
    className='cell_container'
    style={{ backgroundColor: propsCell.data.actions?.disable ? 'transparent' : propsCell.data.color }}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <CellRender {...propsCell} />
  </div>
);

const TooltipStyled = styled(Tooltip)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  marginTop: -4,
}));


export const ColumnRender = (params: ColumnType) => {
  const {
    field, allowFiltering, filterOperations,
    caption, dataField, dataType,
    description, position, minWidth,
    subColumns, fixed, isNotAllowHiding,
    allowEditing, columnHeaderFilter
  } = params;

  const headerCellComponent = () => (
    <>
      {field === 'project' && window.location.href.includes("project/month-timesheet") ? (
        <HeaderCellComponent caption={caption} dataField={dataField} />
      ) : caption}
      {description && (
        <TooltipStyled title={description}>
          <IconButton size="small">
            <InfoIcon fontSize="inherit"/>
          </IconButton>
        </TooltipStyled>
      )}
    </>
  );

  return <Column
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...params}
    headerCellComponent={headerCellComponent}
    visibleIndex={position}
    alignment="left"
    allowFiltering={filterOperations?.length === 0 ? false : allowFiltering}
    caption={caption}
    key={dataField}
    minWidth={minWidth || 50}
    format={dataType === 'date' ? 'dd.MM.yyyy' : undefined}
    dataField={dataField}
    cellRender={cellRender}
    fixed={fixed}
    fixedPosition="left"
    allowHiding={!isNotAllowHiding}
    allowHeaderFiltering={columnHeaderFilter !== undefined}
    headerFilter={{ dataSource: columnHeaderFilter?.dataSource, allowSearch: true }}
    allowEditing={allowEditing}
  >
    {subColumns?.map((column: ColumnType) => (
      <Column
        key={column.dataField}
        dataField={column.dataField}
        caption={column.caption}
        cellRender={cellRender}
        allowEditing={allowEditing}
        allowFiltering={column.filterOperations?.length === 0 ? false : column.allowFiltering}
      />
    ))}
  </Column>
};
