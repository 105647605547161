// Core
import React, { FC, useEffect, memo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SchedulerUI, { Resource } from "devextreme-react/scheduler";
import { AppointmentRenderedEvent } from "devextreme/ui/scheduler";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import fontColorContrast from 'font-color-contrast';
// Parts
import { loadMessages } from "devextreme/localization";
import Map from "./Map";
import FilterScheduler from "./FilterScheduler";
// Hooks
import { useMediaDownMd } from "../../hooks/useDesktopView";
// Core
import { getDashboardCalendarSettingsAsync } from "../../../engine/core/dashboard/saga/asyncAction";
import { selectorsDashboard } from "../../../engine/core/dashboard/selectors";
// Styles
import useStyles from "./styles"
// Config
import ukMessages from "../../../engine/config/devextreme/localization/messages/uk.json";

loadMessages(ukMessages);

let ajaxOptionsDataTypes: string | undefined;
let ajaxOptionsDataAdditionalFilters: string | undefined;
const dataSource = AspNetData.createStore({
  key: 'id',
  loadUrl: 'calendar',
  loadMethod: 'POST',
  onBeforeSend(e, ajaxOptions) {
    if (ajaxOptionsDataTypes !== undefined) {
      // eslint-disable-next-line no-param-reassign
      ajaxOptions.data.types = ajaxOptionsDataTypes;
    }

    if (ajaxOptionsDataAdditionalFilters !== undefined) {
      // eslint-disable-next-line no-param-reassign
      ajaxOptions.data.teamFilters = ajaxOptionsDataAdditionalFilters;
    }
  },
});

const Scheduler: FC = () => {
  const isMobile = useMediaDownMd();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataSettings = useSelector(selectorsDashboard.dashboard.settings.date);
  const loadingSettings = useSelector(selectorsDashboard.dashboard.settings.loading);
  const views = ['day', 'week', 'workWeek', 'month'];
  // eslint-disable-next-line no-unused-vars
  const [widthContainer, setWidthContainer] = useState<number | null>(null);
  const userIsAdmin = document.querySelector('#react_container__portal--dashboard_scheduler')?.getAttribute('data-attr-is-admin');
  ajaxOptionsDataTypes = JSON.stringify(dataSettings.typesAbsences?.map((item: { id: string | number, checked: boolean}) =>
    item.checked
      ? item.id
      : undefined
  ).filter( Boolean ));
  ajaxOptionsDataAdditionalFilters = JSON.stringify(['teams', 'in', dataSettings.selectedTeamsFilter]);

  useEffect(() => {
    dispatch(getDashboardCalendarSettingsAsync())
  }, [dispatch]);

  const onAppointmentRendered = (e: AppointmentRenderedEvent) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    e.appointmentElement.style.color = fontColorContrast(e.appointmentData.color, 0.7);
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    e.appointmentElement.style.opacity = e.appointmentData.approved === undefined || e.appointmentData.approved ? 1 : 0.5;
  };


  const wrapper = document.querySelector('.js--card-body');
  const style = wrapper ? getComputedStyle(wrapper) : undefined;
  const heightFilterContainer = 56;
  const wrapperHeight = wrapper && style ? wrapper.clientHeight - parseFloat(style.paddingTop) - parseFloat(style.paddingBottom) - heightFilterContainer : 700;

  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      const newWidth = entry.contentRect.width;
      setWidthContainer(newWidth);
    }
  });

  wrapper && resizeObserver.observe(wrapper);

  return <div className={classes.wrapper}>
    {
      loadingSettings ? <div>{`${t('loading')}...`}</div> :
        <>
          <div>
            <Map />
          </div>
          <div>
            <div className={classes.filterContainer} style={{ height: `${heightFilterContainer}px` }}>
              <FilterScheduler userIsAdmin={userIsAdmin === 'true'} />
            </div>
            <SchedulerUI
              onAppointmentRendered={onAppointmentRendered}
              dataSource={dataSource}
              remoteFiltering
              height={wrapperHeight < 700 ? 700 : wrapperHeight}
              adaptivityEnabled={isMobile}
              dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
              // @ts-ignore
              views={views}
              defaultCurrentView="month"
              editing={false}
              showAllDayPanel
            >
              <Resource
                fieldExpr='teamsId'
                label='Teams'
                dataSource={dataSettings.selectedTeamsFilter} />
              <Resource
                colorExpr="color"
                fieldExpr="type"
                label={t('Type')}
                displayExpr="name"
                dataSource={dataSettings.typesAbsences}
              />
            </SchedulerUI>
          </div>
        </>
    }
  </div>
}

export default memo(Scheduler);
