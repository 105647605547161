// Core
import React, { FC } from 'react';
// Engine
// MUI
import Tooltip from '@mui/material/Tooltip';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import i18n from "../../../../../../engine/config/i18n";
// Parts
import { IconButton } from "../../../../IconButton";

type ColumnChooserType = { data: { options: { onClick: () => void } } };

export const ColumnChooser:FC<ColumnChooserType> = (props) => {
  const { data } = props;
  return <Tooltip title={i18n.t('reset.columnChooser').toString()} enterTouchDelay={0}>
    <IconButton
      aria-label={i18n.t('reset.columnChooser')}
      size="small"
      onClick={data.options.onClick}
    >
      <ViewColumnIcon fontSize="small"/>
    </IconButton>
  </Tooltip>
};
