// Core
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// MUI
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// Styles
import useStyles from "./styles";
import { setDashBoardCalendarSettingsData } from "../../../engine/core/dashboard/slice";
import { selectorsDashboard } from "../../../engine/core/dashboard/selectors";

type FilterStateType = 'team' | 'company';

type TeamType = {
  id: number
  name: string
}

type FilterSchedulerProps = {
  userIsAdmin: boolean
}

const FilterScheduler: FC<FilterSchedulerProps> = ({ userIsAdmin }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterType, setFilterType] = useState<FilterStateType>(userIsAdmin ? 'company' : 'team');
  const dataSettings = useSelector(selectorsDashboard.dashboard.settings.date);
  const dispatch = useDispatch();

  const onSubmit = (value: string[]) => {
    const newDataSettings = {
      ...dataSettings,
      selectedTeamsFilter: value
    }
    dispatch(setDashBoardCalendarSettingsData(newDataSettings));
  }

  const handleChangeFilterType = (
    event: React.MouseEvent<HTMLElement>,
    newFilterType: FilterStateType,
  ) => {
    if (newFilterType !== null) {
      setFilterType(newFilterType);
      if (newFilterType === 'company') {
        const newDataSettings = {
          ...dataSettings,
          selectedTeamsFilter: []
        }
        dispatch(setDashBoardCalendarSettingsData(newDataSettings));
      }
    }
  };

  const handleChangeTeams = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    onSubmit(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        <InputLabel className={classes.label} id="select_team_label">{t('ChooseTeam')}</InputLabel>
        <Select
          labelId="select_team_label"
          id="select_team_label"
          multiple
          value={dataSettings.selectedTeamsFilter}
          label={t('ChooseTeam')}
          onChange={handleChangeTeams}
          disabled={filterType === 'company'}
          renderValue={(selected) => dataSettings.availableTeamsFilter?.filter((item: TeamType) => selected.includes(String(item.id))).map((filteredItem: TeamType) => filteredItem.name).join(', ')}
        >
          {dataSettings.availableTeamsFilter?.map((teamItem: TeamType) => <MenuItem key={teamItem.id} value={String(teamItem.id)}>
            <Checkbox size='small' checked={dataSettings.selectedTeamsFilter.includes(String(teamItem.id))}/>
            <ListItemText primary={teamItem.name}/>
          </MenuItem>)}
        </Select>
      </FormControl>
      <ToggleButtonGroup
        color="primary"
        value={filterType}
        exclusive
        onChange={handleChangeFilterType}
        size="small"
      >
        <ToggleButton value="team" sx={{ textTransform: 'none' }}>{t('ByTeam')}</ToggleButton>
        {userIsAdmin ? <ToggleButton value="company" sx={{ textTransform: 'none' }}>{t('ByCompany')}</ToggleButton> : null}
      </ToggleButtonGroup>
    </>
  )
}

export default FilterScheduler;
