// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, TableHashCacheType, TableIdType, ToolbarType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

type VacationTableTypes = {
  idUser?: string,
}
const VacationTable:FC<VacationTableTypes & TableIdType & TableHashCacheType & { toolbar: ToolbarType }> = (props) => {
  const { idUser, id, toolbar, hashCache } = props;
  const params = new URLSearchParams(window.location.search)

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    absence_status: params.get('absence_status'),
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    id_user: idUser ? parseInt(idUser, 10) : undefined,
    ...pageParams
  });

  const gridParams = {
    grids: [{ name: 'absences', hashCache }],
  }

  return <Table
    id={id}
    toolbar={toolbar}
    api={axiosAPI.vacation.controller.table}
    apiParams={apiParams}
    gridParams={gridParams}
    gridName="absences"
    sortingMode="multiple"
  />;
}

function VacationTablePortal({ id }: { id: string }) {
  const { t } = useTranslation();
  const Root = document.getElementById(id);
  const idUser = Root?.dataset?.id_user;
  const toolbar = [{
    name: 'absence_status',
    type: 'select',
    placeholder: t('Show'),
    values: Root?.dataset?.toolbar_absence_status || ''
  }];

  return Root ? createPortal(<VacationTable hashCache={Root?.dataset.hashCache} toolbar={toolbar} id={id} idUser={idUser} />, Root) : null;
}

export default VacationTablePortal;
