// Core
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
// DX
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Label, Chart, Series, Export, Legend, Title, Subtitle, Tooltip } from 'devextreme-react/chart';
// Styles
import useStyles from "../styles";
import { axiosAPI } from "../../../../engine/config/axios";

export const ChartsBar = (props: { api?: any; apiParams: object; }) => {
  const {
    api = axiosAPI.charts.data,
    apiParams
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ subTitle, title, color, ...seriesData }, setMetaData] = useState<{
    color?: string,
    subTitle?: string,
    title?: string,
  }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => { // TODO без лоадера криво расчитывается размер графика
    const timeoutID = setTimeout(() => setLoading(true), 1000);
    return () => clearTimeout(timeoutID );
  }, []);

  const dataSource = useMemo(() => new DataSource({
      store: new CustomStore({
        load: () => (
          api(apiParams)
            .then((response: { data: any; }) => {
              setMetaData(response.data.meta);
              return response.data.data;
            })
            .catch(() => { throw new Error('Data Loading Error'); })
        ),
        loadMode: 'raw',
      }),
      paginate: false,
    }), [api, apiParams]);

  const customizeTooltip = (arg: { argumentText: string; value: any;}) => ({
    text: `${arg.argumentText}, ${arg.value}`,
  });

  return !loading ? <div className={classes.loader}>{`${t('loading')}...`}</div> : (
    <div className={classes.wrapper}>
      <Chart id="chart" dataSource={dataSource}>
        <Title margin="10" text={title}>
          <Subtitle text={subTitle}/>
        </Title>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Series color={color} {...seriesData}>
          <Label visible backgroundColor={color}/>
        </Series>
        <Export enabled/>
        <Legend visible={false}/>
        <Tooltip enabled location="center" arrowLength={10} customizeTooltip={customizeTooltip} />
      </Chart>
    </div>
  )
};
