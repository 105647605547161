import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

// Config
const saveMissingTranslate = process.env.REACT_APP_I18N_SAVE_MISSING_TRANSLATE === 'true';
const i18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';
const translateFilePath = process.env.REACT_APP_I18N_FILE_PATH;
export const defaultLng = 'en';
export const lng = document?.getElementById('react--root')?.dataset.locale || defaultLng;

// @ts-ignore
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng,
    preload: ["en", "ru", "uk"],
    debug: i18nDebug,
    fallbackLng: defaultLng,
    saveMissing: saveMissingTranslate,
    nsSeparator: false,
    keySeparator: false,
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
      wait: false,
    },
    backend: {
      loadPath: `${translateFilePath}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
