// Core
import React, { FC } from 'react';
// Engine
// MUI
import Tooltip from '@mui/material/Tooltip';
import UndoIcon from '@mui/icons-material/Undo';
import i18n from "../../../../../../engine/config/i18n";
// Parts
import { IconButton } from "../../../../IconButton";

type ResetEditingType = { data: { options: { onClick: () => void, disabled?: boolean } } };

export const ResetEditing:FC<ResetEditingType> = (props) => {
  const { data } = props;
  return <Tooltip title={i18n.t('reset editing').toString()} enterTouchDelay={0}>
    <div>
      <IconButton
        aria-label={i18n.t('reset editing')}
        size="small"
        onClick={data.options.onClick}
        disabled={data.options.disabled}
      >
        <UndoIcon fontSize="small"/>
      </IconButton>
    </div>
  </Tooltip>
};
