// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';

// Parts
import { getDashboardCalendarSettingsAsync } from './asyncAction';

// Workers
import { callGetDashboardCalendarSettingsWorker } from './workers';

function* watchGetDashboardCalendarSettings() {
  yield takeLatest(getDashboardCalendarSettingsAsync.type, callGetDashboardCalendarSettingsWorker);
}

export function* watchersDashboard(): SagaIterator {
  yield all([
    call(watchGetDashboardCalendarSettings),
  ]);
}
