// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, {
  TableApiParamsType, TableHashCacheType,
} from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";

const params = new URLSearchParams(window.location.search)
const SsoGroupEmployeesTable: FC<TableHashCacheType> = (props) => {
  const { hashCache } = props;

  const gridParams = {
    grids: [{ name: 'sso_group_employees', hashCache }],
  }
  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    filters: loadOptions?.filter,
    sort: loadOptions?.sort,
    sso_group_id: params.get('id'),
    ...pageParams
  });

  return <Table
    keyExpr="id"
    api={axiosAPI.settings.ssoGroupEmployee.table}
    apiParams={apiParams}
    gridParams={gridParams}
    gridName='sso_group_employees'
    sortingMode="multiple"
  />;
}

function SsoGroupEmployeesTablePortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  return Root ? createPortal(<SsoGroupEmployeesTable hashCache={Root?.dataset.hashCache}/>, Root) : null;
}

export default SsoGroupEmployeesTablePortal;
