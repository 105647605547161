import { createTheme } from '@mui/material/styles';

export const FONTS_BASE = [
  'Roboto',
  'sans-serif',
].join(',');

const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 320,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
};

export const COLOR = {
  mainColor: '#1e88e5',
  secondColor: '#C8C8C8',
  third: '#FF7535',
  mainFont: '#222d4a',
  secondFont: '#777E90',
  borderColor: '#D5D5D5',
};

export const currentStyle = {
  breakpoints: BREAKPOINTS,
  palette: {
    primary: {
      main: COLOR.mainColor,
    },
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        text: {
          transform: 'scale(1)',
        },
      },
    },
  },
  typography: {
    fontFamily: FONTS_BASE,
  },
};

const theme = createTheme(currentStyle);

export default theme;
