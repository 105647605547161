// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
// Parts
import Table, { TableApiParamsType, TableHashCacheType } from "../../components/Table";
// Engine
import { axiosAPI } from "../../../engine/config/axios";

type ProjectsTableTypes = {
  idUser?: string,
}

const Projects:FC<ProjectsTableTypes & TableHashCacheType> = (props) => {
  const { hashCache, idUser } = props;
  const gridParams = {
    grids: [{ name: 'projects', hashCache }],
  }

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
      filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      user_id: idUser ? parseInt(idUser, 10) : undefined,
      ...pageParams
    });

  return <Table
    api={axiosAPI.projectsList.table}
    gridParams={gridParams}
    gridName="projects"
    sortingMode="multiple"
    apiParams={apiParams}
  />;
}

function UserProjectsPortal({ id }: { id: string }) {
  const Root = document.getElementById(id);
  const idUser = Root?.dataset?.id_user;
  return Root ? createPortal(<Projects hashCache={Root?.dataset.hashCache} idUser={idUser}  />, Root) : null;
}

export default UserProjectsPortal;
