// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const nameColor = {
  hired: {
    backgroundColor: 'e8fdeb',
    color: '26c6da'
  },
  fired: {
    backgroundColor: 'fff8ec',
    color: 'ffb22b'
  },
  hiredFired: {
    backgroundColor: 'f9e7eb',
    color: 'fc4b6c'
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  user: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "5px 0",
    [theme.breakpoints.down('md')]: {
      maxWidth: '130px',
    },
  },
  info: {
    maxWidth: `calc(100% - 40px)`,
  },
  mood: {
    position: "absolute",
    left: '-9px',
    transition: '150ms',
    '&:hover': {
      transition: '150ms',
      transform: 'scale(1.3)'
    }
  },
  position: {
    color: '#99abb4',
  },
  emailWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  nameHired: {
    backgroundColor: `#${nameColor.hired.backgroundColor}`,
    color: `#${nameColor.hired.color}`,
    borderRadius: 5,
    padding: 3,
  },
  nameFired: {
    backgroundColor: `#${nameColor.fired.backgroundColor}`,
    color: `#${nameColor.fired.color}`,
    borderRadius: 5,
    padding: 3,
  },
  nameHiredFired: {
    backgroundColor: `#${nameColor.hiredFired.backgroundColor}`,
    color: `#${nameColor.hiredFired.color}`,
    borderRadius: 5,
    padding: 3,
  },
  overflowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    [theme.breakpoints.down('md')]: {
      display: "block",
      maxWidth: '200px',
    },
  },
  employeeHired: {
    backgroundColor: 'green',
    color: '#fff',
    borderRadius: 5,
    padding: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    [theme.breakpoints.down('md')]: {
      display: "block",
      maxWidth: '200px',
    },
  },
  employeeFired: {
    backgroundColor: 'red',
    color: '#fff',
    borderRadius: 5,
    padding: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    [theme.breakpoints.down('md')]: {
      display: "block",
      maxWidth: '200px',
    },
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "100%",
    marginRight: "10px",
    objectFit: "cover"
  },
  location: {
    '& i' : {
      color: '#99abb4',
      paddingLeft: 5,
      transition: '150ms',
      cursor: 'pointer',
      '&:hover': {
        color: '#67757c',
        transition: '150ms',
      }
    },
  },
  '@global': {
    '.dx-master-detail-cell': {
      '& .dx_global_user, & .overflowText': {
        maxWidth: '100% !important',
      },
    }
  },
}));

export default useStyles;
