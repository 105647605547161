// Core
import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  calendar: {
    settings: {
      data: {
        typesAbsences: null | [],
        selectedTeamsFilter: string[],
        availableTeamsFilter: null | []
      },
      loading: boolean
    }
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    calendar: {
      settings: {
        data: {
          typesAbsences: null,
          selectedTeamsFilter: [],
          availableTeamsFilter: null
        },
        loading: true,
      }
    }
  } as InitialStateType,
  reducers: {
    setDashBoardCalendarSettingsData(state, action) {
      state.calendar.settings.data = action.payload;
    },
    setDashBoardCalendarSettingsLoading(state, action) {
      state.calendar.settings.loading = action.payload;
    },
  },
});

export const {
  setDashBoardCalendarSettingsData,
  setDashBoardCalendarSettingsLoading
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
