// Core
import React, { FC } from 'react';
// Mui
import NativeSelect from "@mui/material/NativeSelect";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// Parts
import { ToolbarItemType } from "../../..";
import Dialog from "../../../../Dialog";
// Helpers and hooks
import { useMediaDownMd } from "../../../../../hooks/useDesktopView";

const Component:FC<{ item: ToolbarItemType, name: string }> = React.memo((props) => {
  const { item, name } = props;
  const isMobile = useMediaDownMd();
  const onChange = (event: { target: { value: string } }) => window.location.replace(`${window.location.pathname}/?${item.name}=${event.target.value}`);
  const currentValue = window.location.search;
  const defaultValue = currentValue.includes(name) ? currentValue.substring(currentValue.indexOf('=') + 1) : JSON.parse(item?.values || '[]')[0]?.value;

  return (
    <label>
      {!isMobile && <span className="dx-field-toolbar-item-label">{item.placeholder}</span>}
      <NativeSelect
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {JSON.parse(item?.values || '[]').map((valueItem: { label: string, value: string }) => {
          const { label, value } = valueItem;
          return <option key={value} value={value}>{label}</option>;
        })}
      </NativeSelect>
    </label>
  )
});

const DataPicker:FC<{ item: ToolbarItemType, name: string }> = (props) => {
  const isMobile = useMediaDownMd();
  const { item, name } = props;
  return isMobile ? <Dialog iconButton={<ArrowCircleDownIcon fontSize="inherit" />} item={item}><Component item={item} name={name} /></Dialog> : <Component item={item} name={name} />
};

export default DataPicker;
