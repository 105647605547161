// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
// Parts
import Table, { TableApiParamsType, ToolbarType } from "../../../components/Table";
// Engine
import { axiosAPI } from "../../../../engine/config/axios";
// Config
import { tableColumns } from "./tableColumns";

const ReportsOvertimes:FC<{ toolbar: ToolbarType }> = (props) => {
  const { toolbar } = props;
  // @ts-ignore
  const { i18n: { language } }: { i18n: { language: "ru" | "en" } } = useTranslation();
  const params = new URLSearchParams(window.location.search)
  const defaultColumns = [
    // tableColumns[language].actions,
    tableColumns[language].user,
    tableColumns[language].total,
  ];

  const apiParams: TableApiParamsType = (loadOptions, pageParams) => ({
    ot: params.get('ot'),
    date_from: params.get('date_from'),
    date_to: params.get('date_to'),
    filters: loadOptions?.filter,
      sort: loadOptions?.sort,
      ...pageParams
    });

  return <Table
    gridName="reports_overtimes"
    toolbar={toolbar}
    api={axiosAPI.reports.overtimes.table}
    apiParams={apiParams}
    sortingMode="multiple"
    defaultColumns={defaultColumns}
  />;
}

function ReportsOvertimesPortal({ id }: { id: string }) {
  const { t } = useTranslation();
  const Root = document.getElementById(id);
  const toolbar = [{
    type: 'title',
    value: t('Overtimes')
  }];
  return Root ? createPortal(<ReportsOvertimes toolbar={toolbar} />, Root) : null;
}

export default ReportsOvertimesPortal;
